export function getUrlPrefix(): string {
  if (window.location.hostname.startsWith('local')) {
    // e.g. localhost:3000, localmy3.tillr.io
    return 'local';
  }
  if (window.location.hostname.startsWith('test')) {
    return 'test';
  }
  return '';
}

export function getApiBaseUrl(): string {
  // Set in your local machine's environment variables, then restart VS Code, to override this.
  const envVariable = (process.env as { [key: string]: string }).REACT_APP_API_BASE_URL;
  if (envVariable) {
    return envVariable;
  }

  const urlPrefix = getUrlPrefix();
  if (urlPrefix === 'local') {
    return 'http://localhost:7071';
  }
  return `https://${urlPrefix}api3.tillr.io`;
}

export function getAppInsightsConnectionString(): string {
  switch (getUrlPrefix()) {
    case '':
      return 'InstrumentationKey=258ba611-82ba-40c0-9b22-b21a98904e0d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=0762ca37-fa80-4508-8166-79264cc6773a';
    case 'test':
      return 'InstrumentationKey=2ce03214-a4eb-46c6-8814-fdfda800187d;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=5a6782c3-aa6e-4f13-b0b6-eddfb52814c1';
    case 'local':
    default:
      return 'InstrumentationKey=1203ad37-9546-412f-8ff0-64cf3ead7d6e;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=da47ba4b-82d4-425c-90ac-b46ea982b0de';
  }
}

export function isProd(): boolean {
  return getUrlPrefix() === '';
}

interface IOidcConfig {
  oidcProvider: string;
  clientId: string;
  authRedirect: string;
  silentRedirect: string;
}

export function getOidcConfig(): IOidcConfig {
  const urlPrefix = getUrlPrefix();
  let clientDomain;
  let clientId;

  if (urlPrefix === 'local') {
    clientDomain = 'http://localhost:3000';
    clientId = 'my3.tillr.client';
  } else {
    const { hostname } = window.location;
    const subdomain = hostname.substring(urlPrefix.length, hostname.indexOf('.'));
    clientDomain = `https://${urlPrefix}${subdomain}.tillr.io`;
    clientId = `${subdomain}.tillr.client`;
  }

  return {
    oidcProvider: `https://${urlPrefix}accounts.tillr.io/core/`,
    clientId,
    authRedirect: `${clientDomain}/authenticated/`,
    silentRedirect: `${clientDomain}/authenticated/silent-renew`,
  };
}
