import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SortIcon from '@material-ui/icons/Sort';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import React, { useState } from 'react';
import { PaginationOrder } from 'tillr-graphql';
import { getHumanFriendlyLabel } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaced: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  }),
);

interface IProps<TOrderBy> {
  initialProps: IPaginationProps<TOrderBy>;
  options: IPaginationOptions<TOrderBy>;
  onRefetch: (pagination: IPaginationProps<TOrderBy>) => void;
}

interface IPaginationProps<TOrderBy> {
  pageSize: number;
  orderBy: TOrderBy;
  order: PaginationOrder;
}

interface IPaginationOptions<TOrderBy> {
  pageSizes: number[];
  orderBys: TOrderBy[];
  orders: PaginationOrder[];
}

export function PaginationControl<TOrderBy extends string>(props: IProps<TOrderBy>) {
  const classes = useStyles();
  const {
    initialProps,
    options: { pageSizes, orderBys, orders },
    onRefetch,
  } = props;

  const [orderByAnchorEl, setOrderByAnchorEl] = useState<null | HTMLElement>(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState<null | HTMLElement>(null);
  const [pageSize, setPageSize] = useState(initialProps.pageSize);
  const [orderBy, setOrderBy] = useState(initialProps.orderBy);
  const [order, setOrder] = useState(initialProps.order);

  const handleOrderByClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOrderByAnchorEl(event.currentTarget);
  };

  const handleOrderByClose = () => {
    setOrderByAnchorEl(null);
  };

  const handleOrderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleOrderClose = () => {
    setOrderAnchorEl(null);
  };

  const handleChangePageSize = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextPageSize = Number(event.target.value);
    setPageSize(nextPageSize);
    onRefetch({ pageSize: nextPageSize, orderBy, order });
  };

  const handleChangeOrderBy = (event: React.MouseEvent<HTMLDivElement>) => {
    const nextOrderBy = event.currentTarget.dataset.value as TOrderBy;
    setOrderBy(nextOrderBy);
    onRefetch({ pageSize, orderBy: nextOrderBy, order });
    handleOrderByClose();
  };

  const handleChangeOrder = (event: React.MouseEvent<HTMLDivElement>) => {
    const nextOrder = event.currentTarget.dataset.value as PaginationOrder;
    setOrder(nextOrder);
    onRefetch({ pageSize, orderBy, order: nextOrder });
    handleOrderClose();
  };

  return (
    <>
      <Typography variant="body1" display="inline" component="span" className={classes.spaced}>
        Items per page:
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={pageSize}
        onChange={handleChangePageSize}
      >
        {pageSizes.map((x) => (
          <MenuItem key={x} value={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
      <Tooltip title="Order by value">
        <IconButton
          color="inherit"
          aria-label="order by value"
          aria-haspopup="true"
          aria-controls="order-by-value"
          onClick={handleOrderByClick}
        >
          <SortIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="order-by-value"
        anchorEl={orderByAnchorEl}
        keepMounted
        open={Boolean(orderByAnchorEl)}
        onClose={handleOrderByClose}
      >
        <List>
          {orderBys.map((x) => (
            <ListItem
              key={x}
              button
              onClick={handleChangeOrderBy}
              data-value={x}
              selected={x === orderBy}
            >
              <ListItemText primary={getHumanFriendlyLabel(x)} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Tooltip title="Order direction">
        <IconButton
          color="inherit"
          aria-label="order direction"
          aria-haspopup="true"
          aria-controls="order-direction"
          onClick={handleOrderClick}
        >
          <SwapVertIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="order-direction"
        anchorEl={orderAnchorEl}
        keepMounted
        open={Boolean(orderAnchorEl)}
        onClose={handleOrderClose}
      >
        <List>
          {orders.map((x) => (
            <ListItem key={x} button onClick={handleChangeOrder} data-value={x}>
              <ListItemText primary={getHumanFriendlyLabel(x)} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
