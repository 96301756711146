import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { getSiteModuleRoute } from 'components/route-utils';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  title?: string;
  subtitle?: string;
  sublink?: string;
}

export function FormBreadcrumbs(props: IProps) {
  const { title, subtitle, sublink } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;

  return (
    <Breadcrumbs>
      <Link color="inherit" component={RouterLink} to="/">
        Dashboard
      </Link>
      {title !== 'Forms' && (
        <Link
          color="inherit"
          component={RouterLink}
          to={getSiteModuleRoute(siteId, module, 'form-templates')}
        >
          Forms
        </Link>
      )}
      {subtitle && (
        <Link
          color="inherit"
          component={RouterLink}
          to={getSiteModuleRoute(siteId, module, `form-templates/${sublink}`)}
        >
          {subtitle}
        </Link>
      )}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  );
}
