import { Grid, Paper, Typography } from '@material-ui/core';
import { useGetFormTemplates } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { DataTable } from 'components/DataViews/DataTable';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { FilterControl, FilterType, IFilter } from 'components/Filtering/FilterControl';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { PaginationControl } from 'components/Pagination/PaginationControl';
import { PreviousNextControl } from 'components/Pagination/PreviousNextControl';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { ListControls } from 'components/Shared/ListControls';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import {
  FormTemplateFilterBy,
  FormTemplateOrderBy,
  FormTemplatePaginationPropsType,
  PaginationOrder,
} from 'tillr-graphql';
import { FormBreadcrumbs } from './FormBreadcrumbs';

const filterTypeMappings = new Map([[FormTemplateFilterBy.Name, FilterType.String]]);

export function FormTemplates() {
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(FormTemplateOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[paginationOptions.pageSizes.length - 1],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  };

  const { loading, error, data, refetch, fetchMore } = useGetFormTemplates({
    siteId,
    module,
    paginationProps,
  });

  const handleChangeFilter = (nextFilter: IFilter<FormTemplateFilterBy> | null) => {
    refetch({ filterProps: { filters: nextFilter ? [nextFilter] : [] } });
  };

  const handleChangePaginationProps = (nextPaginationProps: FormTemplatePaginationPropsType) => {
    refetch({ paginationProps: nextPaginationProps });
  };

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.formTemplates?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.formTemplates?.endCursor } });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" noWrap display="block">
            Forms
          </Typography>
          <FormBreadcrumbs title="Forms" />
        </Grid>
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.formTemplates && (
        <Paper>
          <ListControls>
            <PaginationControl<FormTemplateOrderBy>
              initialProps={paginationProps}
              options={paginationOptions}
              onRefetch={handleChangePaginationProps}
            />
            <FilterControl<FormTemplateFilterBy>
              filterByValues={Object.values(FormTemplateFilterBy)}
              filterTypeMappings={filterTypeMappings}
              onChange={handleChangeFilter}
            />
            <PreviousNextControl
              hasPreviousPage={data.formTemplates.hasPreviousPage}
              onFetchPrevious={handleFetchPrevious}
              hasNextPage={data.formTemplates.hasNextPage}
              onFetchNext={handleFetchNext}
            />
          </ListControls>
          <HorizontalLine margin={[1, 0, 1, 0]} />
          <DataTable data={data.formTemplates.items} itemRoute="form-templates/{id}/forms" />
          <ListControls>
            <PreviousNextControl
              hasPreviousPage={data.formTemplates.hasPreviousPage}
              onFetchPrevious={handleFetchPrevious}
              hasNextPage={data.formTemplates.hasNextPage}
              onFetchNext={handleFetchNext}
            />
          </ListControls>
        </Paper>
      )}
    </>
  );
}
