import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { SiteListItemFragment } from 'tillr-graphql';
import { AddSite } from './AddSite';
import { EditSiteName } from './EditSiteName';

interface IProps {
  site: SiteListItemFragment;
}

export function SiteOptions(props: IProps) {
  const { site } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="action-button"
        aria-label="options"
        size="small"
        aria-controls="site-options"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`site-options-${site.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <AddSite onCloseMenu={handleClose} parentSiteId={site.id} />
        </MenuItem>
        <MenuItem>
          <EditSiteName onCloseMenu={handleClose} site={site} />
        </MenuItem>
      </Menu>
    </>
  );
}
