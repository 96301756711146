import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { TaskCategoryLabel } from 'components/Tasks/TaskCategoryLabel';
import React, { ChangeEvent } from 'react';
import { useUiDataProvider } from 'UiDataProvider';

const LABEL = 'Select category';

interface IProps {
  value: string;
  onChange: (value: string, label: string) => void;
}

export function TaskCategoryPickList(props: IProps) {
  const { value, onChange } = props;

  const { uiData } = useUiDataProvider();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const nextValue = event.target.value as string;
    onChange(nextValue, uiData!.taskCategories.find((x) => x.value === nextValue)!.name);
  };

  return (
    <>
      {uiData?.taskCategories && (
        <>
          <InputLabel id="taskCategoryPickListLabel">{LABEL}</InputLabel>
          <Select
            label={LABEL}
            labelId="taskCategoryPickListLabel"
            onChange={handleChange}
            value={value}
          >
            {uiData?.taskCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                <TaskCategoryLabel value={category.value} />
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  );
}
