import { createStyles, Grid, GridSize, makeStyles, Paper, Theme } from '@material-ui/core';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { filterBySiteId } from 'utils';
import { Widget } from './Widget';

export type WidgetProps = {
  type: string;
  props?: { [name: string]: any };
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
  includeSiteIds?: Array<number>;
  excludeSiteIds?: Array<number>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
    },
    grid: {
      alignItems: 'stretch',
    },
  }),
);

interface IProps {
  widgets: Array<WidgetProps>;
}

export function Widgets(props: IProps) {
  const { widgets } = props;

  const styles = useStyles();
  const siteContext = useContext(SiteContext);

  if (!siteContext) {
    return null;
  }

  const widgetsToShow = filterBySiteId(widgets, siteContext.siteId);

  if (widgetsToShow.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={3} className={styles.grid}>
      {widgetsToShow.map((widget, widgetIndex) => (
        <Grid
          // eslint-disable-next-line react/no-array-index-key
          key={widgetIndex}
          item
          xs={12}
          sm={widget.sm}
          md={widget.md}
          lg={widget.lg}
          xl={widget.xl}
        >
          <Paper className={styles.paper}>
            <Widget type={widget.type} props={widget.props} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
