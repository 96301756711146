import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import { ElementInfo } from './shared/ElementInfo';
import { HelpText } from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage, validationStyles } from './shared/Validation';

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
  },
  label: {
    paddingTop: 9,
  },
});

export function RadioButton(props: IComponentProps) {
  const classes = useStyles();

  const validationClasses = validationStyles();
  const { field, readonly, modelValue, onValueUpdate, register, errors, setValue, trigger } = props;

  useEffect(() => {
    if (!readonly) {
      if (field.templateOptions?.required) {
        register(
          { name: field.key },
          {
            required: {
              value: field.templateOptions?.required,
              message: getRuleMessage('required'),
            },
          },
        );
      }
      setValue(field.key!, modelValue || '');
      if (field.templateOptions?.required && modelValue) {
        trigger(field.key!);
      }
    }
    // eslint-disable-next-line
  }, []);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: Radio without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
      setValue(field.key!, event.target.value || '');
      if (field.templateOptions?.required) {
        trigger(field.key!);
      }
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl component="fieldset" disabled={readonly}>
          <FormLabel
            id={field.key}
            component="legend"
            required={!readonly && field.templateOptions?.required}
            classes={{
              asterisk: validationClasses.asterisk,
            }}
          >
            {field.templateOptions?.label}
            <ElementInfo inline data={field.data} />
          </FormLabel>
          <RadioGroup
            aria-label={field.templateOptions.label}
            onChange={handleChange}
            value={typeof modelValue !== 'undefined' ? modelValue : ''}
            id={field.key}
          >
            {field.templateOptions.options.map((option, i) => {
              const htmlId = `${field.key}_${i}`;
              const key = `label_${i}`;
              const optionValue = option.value || option.name;
              return (
                <FormControlLabel
                  key={key}
                  value={optionValue}
                  control={<Radio id={htmlId} />}
                  label={option.name}
                  classes={classes}
                />
              );
            })}
          </RadioGroup>
          {errors[field.key!] && (
            <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}
