import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { getAppInsightsConnectionString } from './environment-utils';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: getAppInsightsConnectionString(),
  },
});
appInsights.loadAppInsights();

const IGNORED_TARGETS: string[] = ['https://ipv4.icanhazip.com/'];
appInsights.addDependencyInitializer(({ item }) => {
  if (item.target && IGNORED_TARGETS.includes(item.target)) {
    return false;
  }
  return true;
});

const IGNORED_EXCEPTION_MESSAGES: string[] = [
  'ErrorEvent: ResizeObserver loop completed with undelivered notifications.',
];
appInsights.addTelemetryInitializer((item) => {
  if (
    item.baseData?.exceptions &&
    Array.isArray(item.baseData.exceptions) &&
    item.baseData.exceptions.every((exception: { message: string }) =>
      IGNORED_EXCEPTION_MESSAGES.includes(exception.message),
    )
  ) {
    return false;
  }
  return true;
});

export { appInsights };
