/* eslint-disable react/prop-types */
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import DomainIcon from '@material-ui/icons/Domain';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SecurityIcon from '@material-ui/icons/Security';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { EmptyMessage } from 'components/Shared/EmptyMessage';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatAsFriendlyDate, getHumanFriendlyLabel } from 'utils';
// eslint-disable-next-line import/no-cycle
import { PermissionsAsChips } from 'components/HQ/Tenants/Roles/PermissionsAsChips';
import { UserAvatar } from 'components/Shared/UserAvatar';
import { Detail } from './Detail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      '& .MuiSvgIcon-root': {
        width: '0.8em',
        height: '0.8em',
      },
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        '& .MuiSvgIcon-root': {
          width: '1em',
          height: '1em',
        },
      },
    },
  }),
);

export function DataTable(props: { data: any[]; itemRoute?: string }) {
  const classes = useStyles();
  const { data, itemRoute } = props;
  const history = useHistory();
  if (!(data?.length > 0)) {
    return <EmptyMessage />;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    if (item.id && itemRoute) {
      const route = itemRoute.replace('{id}', item.id);
      history.push(route);
    }
  };

  // TODO - Remove / refactor / re-do-something
  const renderValue = (row: any, param: string, value: any) => {
    const key = param.toLowerCase();
    switch (key) {
      case 'name':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            <div className="like-list-item-avatar">
              {/* TODO: Pass avatar icon as a prop */}
              <Avatar className={classes.avatar}>
                {itemRoute && itemRoute.match(/files/i) && <DescriptionIcon />}
                {itemRoute && itemRoute.match(/forms/i) && <ListAltIcon />}
                {itemRoute && itemRoute.match(/tenants/i) && <DomainIcon />}
                {itemRoute && itemRoute.match(/reports/i) && <ShowChartIcon />}
                {itemRoute && itemRoute.match(/roles/i) && <SecurityIcon />}
              </Avatar>
              <div className="like-list-item-avatar__text">
                <Typography variant="body1" display="block">
                  {value}
                </Typography>
                <Typography variant="body2" display="block">
                  {' '}
                </Typography>
              </div>
            </div>
          </TableCell>
        );
      case 'createddatetime':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            <Detail data={formatAsFriendlyDate(new Date(value))} />
          </TableCell>
        );
      case 'createdbyuser':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            <div className="like-list-item-avatar">
              <UserAvatar name={value.name} id={value.id} />
              <div className="like-list-item-avatar__text">
                <Typography variant="body1" noWrap display="block">
                  {value.name}
                </Typography>
              </div>
            </div>
          </TableCell>
        );
      case 'issubmitted':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            <Typography variant="body1" align="center" style={{ width: 60 }}>
              {value ? '✔' : '✖'}
            </Typography>
          </TableCell>
        );
      case 'tenantid':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
            align="center"
          >
            {value}
          </TableCell>
        );
      case 'ispublished':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            {value && <CheckIcon color="secondary" />}
          </TableCell>
        );
      case 'reportid':
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            {value.substring(0, value.indexOf('-'))}
          </TableCell>
        );
      case 'permissions':
        return (
          <TableCell
            key={key}
            className="user-clickable col--60"
            onClick={(event) => handleClick(event, row)}
          >
            <PermissionsAsChips roles={value} />
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={key}
            className="user-clickable"
            onClick={(event) => handleClick(event, row)}
          >
            {value?.name ? <Detail data={value.name} /> : <Detail data={value} />}
          </TableCell>
        );
    }
  };

  const dataRow = data.filter((x) => x != null)[0];
  // eslint-disable-next-line no-nested-ternary
  const keys = dataRow
    ? typeof dataRow === 'string'
      ? ['value']
      : Object.keys(dataRow).filter((k) => k !== '__typename' && k !== 'id')
    : ['(unknown)'];

  return (
    <>
      <TableContainer>
        <Table className="table-fixed" aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {keys.map((k) => (
                <TableCell key={k} align={k.toLowerCase() === 'id' ? 'right' : 'left'}>
                  {getHumanFriendlyLabel(k)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((x, i) => {
              if (x == null) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow hover key={i}>
                    <TableCell colSpan={keys.length}>
                      <i>null</i>
                    </TableCell>
                  </TableRow>
                );
              }
              if (typeof x === 'string') {
                return (
                  <TableRow hover key={x}>
                    <TableCell>{x}</TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow hover key={x?.id || i}>
                  {keys.map((k) => renderValue(x, k, x[k]))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
