import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useCreateForm, useGetFormTemplate } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleRedirect } from 'components/Modules/ModuleRedirect';
import { getSiteModuleRoute } from 'components/route-utils';
import { PreventTransition } from 'components/Shared/PreventTransition';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import { SiteContext } from 'components/Sites/SiteContext';
import { useLocalStorage } from 'db';
import { HelpText } from 'formly/components/shared/HelpText';
import { FormlyForm } from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { NetworkStatusContext } from 'NetworkStatusContext';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { CreateFormInputType, CreateFormMutationVariables } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { generateUuid } from 'utils';
import { FormBreadcrumbs } from './FormBreadcrumbs';

export function AddForm(props: RouteComponentProps<{ formTemplateId: string }>) {
  const {
    match: {
      params: { formTemplateId },
    },
  } = props;
  const networkStatusContext = useContext(NetworkStatusContext);
  const history = useHistory();
  const userProfile = useContext(UserProfileContext)!;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const queryState = useGetFormTemplate({ siteId, module, id: formTemplateId });
  const [createForm, mutationState] = useCreateForm();
  const [formId] = useState(generateUuid());
  const [instanceName, setInstanceName] = useState('');
  const [templateName, setTemplateName] = useState('');
  const { savedOffline, createOfflineForm } = useLocalStorage(userProfile);

  if (
    queryState.data?.formTemplate &&
    queryState.data.formTemplate.activeFormTemplateId !== formTemplateId
  ) {
    const { activeFormTemplateId } = queryState.data.formTemplate;
    const path = `/form-templates/${activeFormTemplateId}/forms/add`;
    history.push(getSiteModuleRoute(siteId, module, path));
  }

  const handleSubmit = (model: IModel, submit?: boolean) => {
    let modelProp = model;
    let createTasksProp = false;

    if (typeof model.model !== 'undefined' && typeof model.createTasks !== 'undefined') {
      modelProp = model.model;
      createTasksProp = model.createTasks;
    }

    const form: CreateFormInputType = {
      id: formId,
      templateId: formTemplateId,
      name: instanceName,
      formDataJson: JSON.stringify(modelProp),
      isSubmitted: submit ?? false,
    };

    const variables: CreateFormMutationVariables = {
      siteId,
      module,
      form,
      createTasks: createTasksProp,
    };

    if (networkStatusContext) {
      createForm({ variables });
    } else {
      createOfflineForm({
        ...variables,
        template: queryState?.data?.formTemplate?.template || '',
        activeFormTemplateId: queryState?.data?.formTemplate?.activeFormTemplateId,
        templateName,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceName(event.currentTarget.value);
  };

  const handleBlur = (event: any) => {
    if (!event.currentTarget.value.length) {
      setInstanceName(templateName);
    }
  };

  // TODO: Review - This was NOT how it was done originally
  // but there is a package on UAT (and perhaps prod) that
  // is stopping the original, better way from working
  const customFunction = (identifier: string, value: string) => {
    switch (identifier) {
      default: // editNameViaForm
        if (value.length) {
          setInstanceName(value);
        }
    }
  };

  useEffect(() => {
    const getFormTemplateName = () => {
      let name = '';
      if (queryState?.data?.formTemplate?.name) {
        name = queryState.data.formTemplate.name;
      }
      setInstanceName(name);
      setTemplateName(name);
      return name;
    };
    getFormTemplateName();
  }, [queryState]);

  if (mutationState.data?.createForm) {
    return <ModuleRedirect to={`/forms/${mutationState.data.createForm.id}`} />;
  }

  if (savedOffline) {
    return (
      <Redirect
        to={{
          pathname: '/offline-forms',
          state: { create: true },
        }}
      />
    );
  }

  return (
    <>
      {queryState.loading && <ActivityIndicator />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.loading && <ProgressBar />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {queryState.data?.formTemplate && (
        <>
          <PreventTransition active />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h2" display="block">
                <TitlePrefix color="secondary">New</TitlePrefix>
                {templateName ? <>{templateName}</> : <>Form</>}
              </Typography>
              <FormBreadcrumbs
                title={instanceName || templateName}
                subtitle={templateName || 'Form'}
                sublink={`${formTemplateId}/forms`}
              />
            </Grid>
          </Grid>
          <Divider className="divider" />

          <Card>
            <CardContent>
              <form noValidate autoComplete="off">
                <fieldset>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} className="tillr-form-grid-element">
                      <Typography variant="h5" gutterBottom>
                        Form name
                      </Typography>
                      <TextField
                        id="name"
                        value={instanceName}
                        variant="outlined"
                        onChange={handleChange}
                        required
                        fullWidth
                        onBlur={handleBlur}
                      />
                      <HelpText value="Edit this value to give your form a more identifiable name" />
                    </Grid>
                  </Grid>
                </fieldset>
              </form>
            </CardContent>
          </Card>

          <Divider className="divider" />

          <FormlyForm
            formId={formId}
            formTemplateId={formTemplateId}
            customFunctions={[customFunction]}
            mode="create"
            config={JSON.parse(queryState.data.formTemplate.template)}
            onSubmit={handleSubmit}
            submitting={mutationState.loading}
            fixedButtons
          />
        </>
      )}
    </>
  );
}
