import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { TaskCategoryLabel } from 'components/Tasks/TaskCategoryLabel';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import { useUiDataProvider } from 'UiDataProvider';
import { isArrayNotEmpty } from 'utils';
import { HelpText } from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage, hasFieldError } from './shared/Validation';

export function TaskCategory(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
  } = props;

  const customTrigger = () => {
    const values = getValues();
    if (
      field.templateOptions?.required &&
      field.key &&
      (values[field.key] === undefined || values[field.key] === '')
    ) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  const { uiData } = useUiDataProvider();

  useEffect(() => {
    if (!readonly) {
      register({ name: field.key });
      setValue(field.key, modelValue);
      customTrigger();
    }
    // eslint-disable-next-line
  }, []);

  if (!isArrayNotEmpty(uiData?.taskCategories)) {
    console.warn('FORMLY: TaskCategory but no taskCategories in UiData.');
    return null;
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (field.key) {
      onValueUpdate(field.key, event.target.value);
      setValue(field.key!, event.target.value !== 'undefined' ? event.target.value : '');
      customTrigger();
    }
  };

  const label = field.templateOptions?.label ?? 'Priority';

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl
          variant="outlined"
          required={!readonly && field.templateOptions?.required}
          disabled={readonly}
          error={hasFieldError(field.key!, errors, formState)}
        >
          <InputLabel id={field.key} required={!readonly && field.templateOptions?.required}>
            {label}
          </InputLabel>
          <Select
            label={label}
            labelId={field.key}
            id={field.key}
            onChange={handleChange}
            value={typeof modelValue !== 'undefined' ? modelValue : ''}
            fullWidth
          >
            {!field.templateOptions?.required && <MenuItem value="">None</MenuItem>}
            {uiData!.taskCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                <TaskCategoryLabel value={category.value} />
              </MenuItem>
            ))}
          </Select>
          {hasFieldError(field.key!, errors, formState) && (
            <FieldValidationMessage message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}
