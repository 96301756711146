import { Grid, Paper, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { useGetSitesAdmin } from 'apollo-hooks';
import { AdminBreadcrumbs } from 'components/Admin/AdminBreadcrumbs';
import { AdminNavigation } from 'components/Admin/AdminNavigation';
import { useDetectTenantType } from 'components/Custom/useDetectTenantType';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { InfoTextBlock } from 'components/Shared/InfoTextBlock';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import React, { useEffect, useState } from 'react';
import { SiteListItemFragment } from 'tillr-graphql';
import { LoadingSites } from './LoadingSites';
import { SiteOptions } from './SiteOptions';
import { siteStyles } from './siteStyles';

interface StyledTreeItemProps {
  bgColor?: string;
  color?: string;
  site: SiteListItemFragment;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  nodeId: string;
  children: React.ReactNode;
}

export function Sites() {
  const classes = siteStyles();
  const { loading, error, data } = useGetSitesAdmin();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [tenantType] = useDetectTenantType();

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    event.persist();
    const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  useEffect(() => {
    let expandedArray: string[] = [];
    if (data?.sitesAdmin) {
      expandedArray = data?.sitesAdmin.map((site) => site.id.toString());
    }
    setExpanded(expandedArray);
  }, [data, setExpanded]);

  function StyledTreeItem(props: StyledTreeItemProps) {
    const {
      site,
      labelText,
      labelIcon: LabelIcon,
      labelInfo,
      color,
      bgColor,
      nodeId,
      ...other
    } = props;

    return (
      <TreeItem
        nodeId={nodeId}
        label={
          <div className={classes.labelRoot}>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <SiteOptions site={site} />
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  }

  const renderTree = (sites: Array<SiteListItemFragment>, parentSiteId: number | null) => {
    let childSites = parentSiteId
      ? sites.filter((x) => x.parentSiteId === parentSiteId)
      : // All sites that don't have a parent also in the list
        sites.filter((x) => !sites.map((y) => y.id).includes(x.parentSiteId || 0));
    childSites = childSites.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <StyledTreeItem
              nodeId={x.id.toString()}
              key={x.id}
              site={x}
              labelText={`${x.name}${x.externalId ? ` (${x.externalId})` : ''}`}
              labelIcon={SupervisorAccountIcon}
              labelInfo="90"
            >
              {renderTree(sites, x.id)}
            </StyledTreeItem>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Sites
            </>
          </Typography>
          <AdminBreadcrumbs title="Sites" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      <AdminNavigation path="/admin/sites" />

      {tenantType === 'squirrel' && (
        <>
          <InfoTextBlock>
            Practices listed in Squirrel can be added or amended on this page.
            <br />
            <strong>Please only use if you’re trained to do so.</strong>
            <br />
            All changes in agreement with central office compliance team.
            <br />
            The list relationships work as &apos;parent and child&apos;.
            <br />
            Admin site is the top-level parent, with main practices being a child of that, and
            branches being a child site of the main practice.
          </InfoTextBlock>
        </>
      )}

      <HorizontalLine margin={[2, 0, 2, 0]} />

      {loading && (
        <>
          <ProgressBar />
          <LoadingSites />
        </>
      )}
      {error && <ErrorDisplay error={error} />}
      {data && data.sitesAdmin && (
        <Paper
          classes={{
            root: classes.paperRoot,
          }}
        >
          <TreeView
            className={classes.root}
            expanded={expanded}
            defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
            defaultExpandIcon={<ChevronRightIcon color="primary" />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            onNodeToggle={handleToggle}
          >
            {renderTree(data.sitesAdmin, null)}
          </TreeView>
        </Paper>
      )}
    </>
  );
}
