/* eslint-disable import/prefer-default-export */
import { FilterType } from 'components/Filtering/FilterControl';
import { isBefore } from 'date-fns';
import { IAssignee } from 'formly/components/Assignees';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { COLORS } from 'themes/colors';
import { FilterOperation, ParentType, TaskFragment, TaskListItemFragment } from 'tillr-graphql';
import { formatAsFriendlyDate, getHumanFriendlyLabel } from 'utils';

export function formatTaskAssignees(task: TaskFragment) {
  const assignees: IAssignee[] = task.assignedToUsers
    .map((x) => ({ id: x.id, name: x.name, type: 'User' } as IAssignee))
    .concat(
      task.assignedToTeams.map((x) => ({ id: x.id, name: x.name, type: 'Team' } as IAssignee)),
    );

  return { ...task, assignees };
}

export function getFilterLabel<TFilterBy extends string>(
  filterBy: TFilterBy,
  filterType: FilterType,
  operation: FilterOperation,
  argument: string,
): string {
  const name = getHumanFriendlyLabel(filterBy);
  const condition = getHumanFriendlyLabel(operation).toLocaleLowerCase();
  const value = filterType === FilterType.String ? `"${argument}"` : argument;

  return `${name} ${condition} ${value}`;
}

export function getTaskStatusFeatures(task: TaskListItemFragment): {
  status: string;
  label: string;
  color: string;
} {
  const dueDate = new Date(task.dueDate);
  const formattedDueDate = formatAsFriendlyDate(dueDate);

  if (task.open) {
    if (isBefore(new Date(), dueDate)) {
      return { status: 'Open', label: `Due: ${formattedDueDate}`, color: COLORS.TASKS.OPEN };
    }
    return {
      status: 'Overdue',
      label: `Overdue: ${formattedDueDate}`,
      color: COLORS.TASKS.OVERDUE,
    };
  }

  if (task.completedDate) {
    const completedDate = new Date(task.completedDate);
    const formattedCompletedDate = formatAsFriendlyDate(completedDate);
    return {
      status: 'Completed',
      label: `Completed: ${formattedCompletedDate}\n(was due: ${formattedDueDate})`,
      color: isBefore(completedDate, dueDate)
        ? COLORS.TASKS.COMPLETED_ON_TIME
        : COLORS.TASKS.COMPLETED_LATE,
    };
  }

  // Shouldn't happen, but possible, so be sure we don't crash
  return {
    status: 'Closed',
    label: `(was due: ${formattedDueDate})`,
    color: COLORS.TASKS.COMPLETED_ON_TIME,
  };
}

export function getFormlyConfig(
  mode: 'add' | 'edit' | 'view',
  createdDateTime?: string,
): IFormlyConfig {
  return {
    fields: [
      ...(mode === 'view' || mode === 'edit'
        ? [
            {
              id: 'taskform-status',
              type: 'tlrSelect',
              templateOptions: {
                required: true,
                options: [
                  {
                    name: 'Open',
                    value: true,
                  },
                  {
                    name: 'Closed',
                    value: false,
                  },
                ],
                label: 'Status',
              },
              className: 'col-xs-6 clear',
              key: 'open',
              defaultValue: 'Open',
            },
            {
              id: 'taskform-completed',
              type: 'datepicker',
              data: {
                minDate: createdDateTime,
                maxDate: new Date(),
              },
              templateOptions: {
                label: 'Completed date',
                required: true,
              },
              className: 'col-xs-6 pull-right',
              key: 'completedDate',
              hideExpression: "model['open'] !== false",
            },
          ]
        : []),
      {
        id: 'taskform-name',
        type: 'input',
        templateOptions: {
          label: 'Name',
          required: true,
        },
        key: 'name',
        className: 'col-xs-12 col-sm-12',
      },
      {
        id: 'taskform-description',
        type: 'textarea',
        templateOptions: {
          required: true,
          label: 'Description',
        },
        className: 'col-xs-12 col-sm-12',
        key: 'description',
      },
      {
        id: 'taskform-assignees',
        type: 'assignees',
        templateOptions: {
          label: 'Assignee(s)',
          required: true,
        },
        key: 'assignees',
        className: 'col-xs-12 col-sm-12',
      },
      {
        id: 'taskform-duedate',
        type: 'datepicker',
        data: {},
        templateOptions: {
          required: true,
          label: 'Due date',
          disabled: mode === 'edit',
        },
        className: 'col-xs-6 clear',
        key: 'dueDate',
      },
      {
        id: 'taskform-category',
        type: 'taskcategory',
        data: {},
        templateOptions: {
          label: 'Category',
        },
        className: 'col-xs-6',
        key: 'category',
      },
      {
        id: 'taskform-attachments',
        type: 'attachments',
        templateOptions: {
          required: false,
          label: 'Attachments',
        },
        data: {
          parentTypeForAttachments: ParentType.Task,
        },
        className: 'col-xs-12 col-sm-12',
        key: 'attachments',
      },
    ],
  };
}
