import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useCloseMyTask, useGetMyTask } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { SiteContext } from 'components/Sites/SiteContext';
import { FormlyForm } from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useContext } from 'react';
import { CloseMyTaskMutationVariables } from 'tillr-graphql';

interface IProps {
  taskId: string;
}

export function CloseTaskCheckBox(props: IProps) {
  const { taskId } = props;
  const [open, setOpen] = React.useState(false);

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const queryState = useGetMyTask({ siteId, module, id: taskId });
  const [closeMyTask, mutationState] = useCloseMyTask({ siteId, module, id: taskId });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (model: IModel) => {
    const mutationVariables: CloseMyTaskMutationVariables = {
      siteId,
      module,
      taskId,
      completedDateTime: model.completedDate,
    };
    closeMyTask({ variables: mutationVariables }).then((response) => {
      if (response?.data) {
        handleClose();
      }
    });
  };

  const config: IFormlyConfig = {
    settings: {
      manage: {
        save: 'Save',
      },
    },
    fields: [
      {
        id: 'taskform-completed',
        type: 'datepicker',
        data: {
          minDate: queryState.data?.myTask?.createdDateTime,
          maxDate: new Date(),
        },
        templateOptions: {
          label: 'Completed date',
          required: true,
        },
        className: 'col-xs-12 clear',
        key: 'completedDate',
      },
    ],
  };

  return (
    <>
      <Tooltip title="Close task">
        <IconButton
          aria-label="Done"
          onClick={handleClickOpen}
          color="secondary"
          style={{ marginLeft: -12 }}
        >
          <CheckBoxOutlineBlankIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Close task</DialogTitle>
        <DialogContent>
          {mutationState.error && <ErrorDisplay error={mutationState.error} />}
          <FormlyForm
            formId={taskId}
            mode="create"
            config={config}
            model={{ completedDate: new Date().toISOString() }}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            modal
            hideMandatoryMessage
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
