import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { useGetForm } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleLinkButton } from 'components/Modules/ModuleLinkButton';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { SiteContext } from 'components/Sites/SiteContext';
import { FormlyForm } from 'formly/FormlyForm';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { AddFormButton } from './AddFormButton';
import { DuplicateForm } from './DuplicateForm';
import { FormBreadcrumbs } from './FormBreadcrumbs';

export function Form(props: RouteComponentProps<{ formId: string }>) {
  const {
    match: {
      params: { formId },
    },
  } = props;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetForm({ siteId, module, id: formId });
  const [editPermissions, setEditPermissions] = useState(['Forms.Edit']);

  useEffect(() => {
    if (data?.form?.isConfidential) {
      setEditPermissions((previous) => {
        const newPermissions = [...previous, 'Forms.EditConfidential'];
        return newPermissions;
      });
    }
  }, [data, setEditPermissions]);

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data &&
        (data.form ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" display="block">
                  {data.form.name}
                </Typography>
                {/* TODO: Replace 'Form Instances' with the Form Template Name */}
                <FormBreadcrumbs
                  title={data.form.name}
                  subtitle="Form Instances"
                  sublink={`${data.form.formTemplate.activeFormTemplateId}/forms`}
                />
              </Grid>
            </Grid>
            <HorizontalLine margin={[2, 0, 2, 0]} />
            <ActionButtons permission={editPermissions}>
              {/* TODO: `allowDuplicate: false` on template json? */}
              {data.form.formTemplate.name !== 'RC Management Inspections' && (
                <DuplicateForm instance={data.form} />
              )}
              <ModuleLinkButton
                to={`/forms/${formId}/edit`}
                startIcon={<EditIcon />}
                tooltip={
                  data.form.isSubmitted
                    ? 'This form has been submitted and can no longer be edited'
                    : 'Edit form'
                }
                disabled={data.form.isSubmitted}
              >
                Edit
              </ModuleLinkButton>
              <AddFormButton
                templateId={data.form.templateId}
                templateName={data.form.formTemplate.name}
              />
            </ActionButtons>

            <FormlyForm
              formId={data.form.id}
              formTemplateId={data.form.templateId}
              mode="readonly"
              config={JSON.parse(data.form.template)}
              model={JSON.parse(data.form.formDataJson)}
            />
          </>
        ) : (
          !error && (
            <p>
              <i>Not found</i>
            </p>
          )
        ))}
    </>
  );
}
