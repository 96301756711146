import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useCreateForm } from 'apollo-hooks';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleRedirect } from 'components/Modules/ModuleRedirect';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext, useState } from 'react';
import { CreateFormInputType, CreateFormMutationVariables } from 'tillr-graphql';
import { generateUuid } from 'utils';

export function DuplicateForm(props: { instance: any }) {
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { instance } = props;
  const [open, setOpen] = useState(false);
  const [createForm, mutationState] = useCreateForm();
  const [formId] = useState(generateUuid());
  const { templateId, formDataJson, name } = instance;
  const [instanceName, setInstanceName] = useState(`Copy of ${name}`);

  const handleSubmit = () => {
    const form: CreateFormInputType = {
      id: formId,
      templateId,
      name: instanceName,
      formDataJson,
      isSubmitted: false,
    };

    const variables: CreateFormMutationVariables = { siteId, module, form };
    createForm({ variables });
  };

  if (mutationState.data?.createForm) {
    return <ModuleRedirect to={`/forms/${mutationState.data.createForm.id}`} />;
  }

  const handleChange = (event: any) => {
    setInstanceName(event.currentTarget.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isFormValid = !!instanceName.length;

  return (
    <>
      <Tooltip title="Duplicate this form">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          <FileCopyIcon />
          Duplicate
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Duplicate form</DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new title for this form.</DialogContentText>
          <Grid item xs={12} sm={12} className="tillr-form-grid-element">
            <TextField
              id="signee"
              value={instanceName || ''}
              variant="outlined"
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <FormsButtons>
            <CancelButton label="Cancel" onClick={handleClose} />
            <ProgressButton label="Save" onClick={handleSubmit} disabled={!isFormValid} />
          </FormsButtons>
        </DialogContent>
      </Dialog>
    </>
  );
}
