import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const siteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: '#fcefe3',
        color: theme.palette.primary.main,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    paperRoot: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 0, 0),
    },
    content: {
      marginBottom: 2,
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${theme.palette.text.primary}`,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: '#000',
      '& .MuiTypography-body2': {
        fontSize: '1rem',
        color: theme.palette.text.primary,
      },
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    disabled: {
      '& .MuiSvgIcon-root': {
        color: '#ccc',
        cursor: 'not-allowed',
      },
    },
  }),
);

export { siteStyles };

