import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SiteControl2 } from 'components/Sites/SiteControl2';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useUiDataProvider } from 'UiDataProvider';
import { UserManagerContext } from 'UserManagerContext';
import { UserProfileContext } from 'UserProfileContext';
import { formatAsFriendlyDate, getStartUpRoute } from 'utils';
import { QuickLinks } from './QuickLinks';
import { Widgets } from './Widgets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
    },
    image: {
      maxWidth: '480px',
      display: 'block',
      margin: '104px auto 0',
      [theme.breakpoints.down('xs')]: {
        margin: '16px auto 0',
      },
    },
    welcomeHeader: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(2),
    },
    welcomeText: {
      fontSize: '1.2rem',
      marginBottom: theme.spacing(2),
    },
    grid: {
      alignItems: 'stretch',
    },
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiListItemText-primary': {
        fontSize: 16.5,
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    highlight: {
      fontWeight: 'normal',
      color: theme.palette.primary.main,
    },
  }),
);

export function Dashboard() {
  const classes = useStyles();
  const today = formatAsFriendlyDate(new Date());
  const userProfile = useContext(UserProfileContext);

  const [redirectPath, setRedirectPath] = useState<null | string>(null);
  const { uiData } = useUiDataProvider();

  // This is temporary while rolling out Permissions
  const userManager = useContext(UserManagerContext)!;
  if (userProfile && !userProfile?.permissions?.length) {
    userManager.signoutRedirect();
  }

  const userName = userProfile?.name;

  useEffect(() => {
    if (userProfile) {
      const route = getStartUpRoute(true);
      if (route && route !== window.location.pathname) {
        setRedirectPath(route);
      }
    }
  }, [userProfile, setRedirectPath]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            Welcome back
            {userName && userName.length > 0 && <>{`, ${userName}`}</>}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className="bottom-align">
          <Typography variant="body2" align="right">
            {today}
          </Typography>
        </Grid>
      </Grid>

      <Divider className="divider" />

      {uiData && (uiData.showSiteControl || uiData.quickLinks) && (
        <Box mb={3}>
          <Paper className={classes.paper}>
            {uiData.showSiteControl && (
              <Box>
                <SiteControl2 />
              </Box>
            )}
            {uiData.quickLinks && <QuickLinks links={uiData.quickLinks} />}
          </Paper>
        </Box>
      )}

      {uiData?.dashboards && (
        <div className={classes.root}>
          {uiData.dashboards.map((dashboard, dashboardIndex) => {
            if (!userProfile?.hasAnyPermission(dashboard.permissions)) {
              return null;
            }
            return <Widgets key={dashboardIndex} widgets={dashboard.widgets} />;
          })}
        </div>
      )}
    </Grid>
  );
}
