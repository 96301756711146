import { Box, Chip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useGetMyTasks } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { FilterControl, FilterType, IFilter } from 'components/Filtering/FilterControl';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleLinkButton } from 'components/Modules/ModuleLinkButton';
import { PaginationControl } from 'components/Pagination/PaginationControl';
import { PreviousNextControl } from 'components/Pagination/PreviousNextControl';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { ListControls } from 'components/Shared/ListControls';
import { ToggleButtons } from 'components/Shared/ToggleButtons';
import { SiteContext } from 'components/Sites/SiteContext';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import {
  FilterOperation,
  PaginationOrder,
  TaskFilterBy,
  TaskOrderBy,
  TaskPaginationPropsType,
} from 'tillr-graphql';
import { isNotNullish } from 'utils';
import { SwitchViewControl } from '../SwitchViewControl';
import { QuickTask } from './QuickTask';
import { ResultsTable } from './ResultsTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    actions: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

const filterTypeMappings = new Map([
  [TaskFilterBy.Assignee, FilterType.Assignee],
  [TaskFilterBy.DueDate, FilterType.Date],
  [TaskFilterBy.Name, FilterType.String],
  [TaskFilterBy.Category, FilterType.TaskCategory],
]);

export function TasksList() {
  const classes = useStyles();

  const [filterMode, setFilterMode] = useState<'open' | 'upcoming' | 'all'>('open');

  const [filter1, setFilter1] = useState<IFilter<TaskFilterBy> | null>();
  const [filter2, setFilter2] = useState<IFilter<TaskFilterBy> | null>();
  const [filter2Label, setFilter2Label] = useState<string>();

  const [filter2ControlId, setFilter2ControlId] = useState(0);

  useEffect(() => {
    setFilter1(() => {
      switch (filterMode) {
        case 'open': {
          return {
            filterBy: TaskFilterBy.Closed,
            operation: FilterOperation.Equals,
            argument: 'false',
          };
        }
        case 'upcoming': {
          return {
            filterBy: TaskFilterBy.DueDate,
            operation: FilterOperation.GreaterThan,
            argument: format(new Date(), 'dd MMM yyyy'),
          };
        }
        case 'all': {
          return null;
        }
        default: {
          throw new Error(`Unknown filterMode: ${filterMode}.`);
        }
      }
    });
  }, [filterMode]);

  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(TaskOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const [paginationProps, setPaginationProps] = useState<TaskPaginationPropsType>({
    pageSize: paginationOptions.pageSizes[2],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  });

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data, refetch, fetchMore } = useGetMyTasks({
    siteId,
    module,
    filterProps: {
      filters: [filter1, filter2].filter(isNotNullish),
    },
    paginationProps,
  });

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.myTasks?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.myTasks?.endCursor } });
  };

  const handleChangeFilter2 = (nextFilter: IFilter<TaskFilterBy> | null, label?: string) => {
    setFilter2(nextFilter);
    setFilter2Label(label);
  };

  const handleDeleteFilter2 = () => {
    handleChangeFilter2(null);
    setFilter2ControlId((prev) => prev + 1);
  };

  const handleChangePaginationProps = (nextPaginationProps: TaskPaginationPropsType) => {
    setPaginationProps(nextPaginationProps);
  };

  const handleQuickTaskCreated = () => {
    refetch();
  };

  return (
    <div>
      {error && <ErrorDisplay error={error} />}
      <Grid container alignItems="center" className={classes.root}>
        <Grid md={6} container item>
          <SwitchViewControl currentView="list" />
        </Grid>
        <Grid
          md={6}
          container
          item
          // justify="flex-end"
          alignItems="center"
          className={classes.actions}
        >
          <ActionButtons margin="no-inner-margin" permission={['Tasks.Edit']}>
            <QuickTask onCreated={handleQuickTaskCreated} />
            <ModuleLinkButton to="/tasks/add" startIcon={<AddIcon />} tooltip="Create new task">
              Create new
            </ModuleLinkButton>
          </ActionButtons>
        </Grid>
      </Grid>
      <Paper>
        <ListControls
          left={
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>
                <Typography>Show:</Typography>
              </Box>
              <Box mr={1}>
                <ToggleButtons
                  values={['open', 'upcoming', 'all']}
                  value={filterMode}
                  onChange={setFilterMode}
                  aria-label="Tasks filter"
                />
              </Box>
              {filter2 && (
                <Chip label={filter2Label} color="secondary" onDelete={handleDeleteFilter2} />
              )}
            </Box>
          }
        >
          <PaginationControl<TaskOrderBy>
            initialProps={paginationProps}
            options={paginationOptions}
            onRefetch={handleChangePaginationProps}
          />
          <FilterControl<TaskFilterBy>
            key={filter2ControlId}
            filterByValues={Object.values(TaskFilterBy)}
            filterTypeMappings={filterTypeMappings}
            onChange={handleChangeFilter2}
          />
          <PreviousNextControl
            hasPreviousPage={data?.myTasks?.hasPreviousPage ?? false}
            onFetchPrevious={handleFetchPrevious}
            hasNextPage={data?.myTasks?.hasNextPage ?? false}
            onFetchNext={handleFetchNext}
          />
        </ListControls>
        <HorizontalLine margin={[1, 0, 1, 0]} />
        <ResultsTable loading={loading} tasks={data?.myTasks?.items} />
        <ListControls>
          <PreviousNextControl
            hasPreviousPage={data?.myTasks?.hasPreviousPage ?? false}
            onFetchPrevious={handleFetchPrevious}
            hasNextPage={data?.myTasks?.hasNextPage ?? false}
            onFetchNext={handleFetchNext}
          />
        </ListControls>
      </Paper>
    </div>
  );
}
