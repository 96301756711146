import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { EmptyMessage } from 'components/Shared/EmptyMessage';
import { UserAvatar } from 'components/Shared/UserAvatar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IAppTheme } from 'themes/useGetTheme';
import { TaskListItemFragment } from 'tillr-graphql';
import { TaskCategoryLabel } from '../TaskCategoryLabel';
import { AssigneesSummary } from './AssigneesSummary';
import { QuickViewButton } from './QuickViewButton';
import { TaskStatus } from './TaskStatus';

const useStyles = makeStyles((theme: IAppTheme) => ({
  automated: {
    backgroundColor: theme.automatedTasks?.defaultColor,
  },
}));

interface IProps {
  loading: boolean;
  tasks: TaskListItemFragment[] | undefined;
}

export function ResultsTable(props: IProps) {
  const classes = useStyles();
  const { loading, tasks } = props;
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { taskId } = event.currentTarget.dataset;
    if (taskId) {
      history.push(`tasks/${taskId}`);
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Assignee(s)</TableCell>
              <Hidden smDown>
                <TableCell>Creator</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell>Status</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell align="right">Actions</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || tasks === undefined || tasks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  {loading ? <ActivityIndicator /> : <EmptyMessage />}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {tasks.map((task) => (
                  <TableRow key={task.id} hover>
                    <TableCell
                      className="user-clickable"
                      data-task-id={task.id}
                      onClick={handleClick}
                    >
                      <div className="like-list-item-avatar">
                        <Hidden smDown>
                          <Avatar
                            className={task.createdByFormTemplateName ? classes.automated : ''}
                          >
                            <AssignmentTurnedInIcon />
                          </Avatar>
                        </Hidden>
                        <div className="like-list-item-avatar__text">
                          <Typography variant="body1" display="block">
                            {task.name}
                          </Typography>
                          {task.createdByFormTemplateName && (
                            <Typography variant="body2" display="block">
                              {task.createdByFormTemplateName}
                            </Typography>
                          )}
                          <Typography variant="body2" display="block">
                            Ref: T{String(task.referenceNumber).padStart(4, '0')}
                          </Typography>
                          {task.category && (
                            <Typography variant="body2" display="block">
                              Category: <TaskCategoryLabel value={task.category} />
                            </Typography>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="user-clickable"
                      data-task-id={task.id}
                      onClick={handleClick}
                    >
                      <AssigneesSummary users={task.assignedToUsers} teams={task.assignedToTeams} />
                    </TableCell>
                    <Hidden smDown>
                      <TableCell
                        className="user-clickable"
                        data-task-id={task.id}
                        onClick={handleClick}
                      >
                        <UserAvatar name={task.createdByUser.name} id={task.createdByUser.id} />
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell>
                        <TaskStatus task={task} />
                      </TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell align="right">
                        <QuickViewButton taskId={task.id} />
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
