import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  }),
);

interface IProps {
  left?: React.ReactNode;
  children: React.ReactNode;
}

export function ListControls(props: IProps) {
  const classes = useStyles();
  const { left, children } = props;
  return (
    <Grid container justify="space-between" alignItems="center" className={classes.root}>
      <Grid item>{left}</Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}
