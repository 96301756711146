/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockIcon from '@material-ui/icons/Lock';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { useGetFileTypes } from 'apollo-hooks';
import clsx from 'clsx';
import { parseAndSortFileTypes } from 'components/Files/utils';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleControl } from 'components/Modules/ModuleControl';
import { getSiteModuleRoute } from 'components/route-utils';
import { SiteContext } from 'components/Sites/SiteContext';
import { SiteControl } from 'components/Sites/SiteControl';
import { SiteTitle } from 'components/Title/SiteTitle';
import { useGetOfflineFormsCount } from 'db';
import React, { useContext } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import { UserProfileContext } from 'UserProfileContext';
import { formatItemCount } from 'utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'relative',
        overflow: 'hidden',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    expand: {
      minWidth: 'auto',
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    scrollable: {
      height: '100%',
      // overflowY: 'scroll',
      position: 'relative',
      top: '64px',
      paddingBottom: '64px',
    },
    navFooter: {
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
      minHeight: '64px',
    },
  }),
);

interface ISettings {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

interface INavItem {
  linkTo: string;
  icon: any;
  label: string;
  permissions?: string[];
  display?: boolean;
  childLinks?: ListItemLinkProps[];
}

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  className?: string;
}

interface ListItemCollapseButtonProps {
  icon?: React.ReactElement;
  primary: string;
  index: number;
  className?: string;
}

export function Nav(props: ISettings) {
  const classes = useStyles();
  const theme = useTheme();
  const userProfile = useContext(UserProfileContext);
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { drawerOpen, toggleDrawer } = props;
  const container = window !== undefined ? () => window.document.body : undefined;
  const { data: fileTypesData } = useGetFileTypes({ module });

  function ChildIcon(iconName?: string) {
    const icon = iconName && iconName.match(/invalid/) ? 'description' : iconName;
    return <Icon>{icon}</Icon>;
  }

  let navItems: INavItem[] = [
    {
      linkTo: getSiteModuleRoute(siteId, module, 'dashboard'),
      icon: HomeIcon,
      label: 'Dashboard',
    },
  ];

  if (fileTypesData?.fileTypes?.length) {
    navItems = navItems.concat([
      {
        linkTo: getSiteModuleRoute(siteId, module, 'file-types'),
        icon: DescriptionIcon,
        label: 'Files',
        permissions: ['Files.View'],
        childLinks: parseAndSortFileTypes(fileTypesData.fileTypes).map((x) => ({
          icon: ChildIcon(x.uiData.materialIcon),
          primary: x.name,
          to: `/file-types/${x.id}`,
        })),
      },
    ]);
  }
  navItems = navItems.concat([
    {
      linkTo: getSiteModuleRoute(siteId, module, 'form-templates'),
      icon: ListAltIcon,
      label: 'Forms',
      permissions: ['Forms.View'],
    },
    {
      linkTo: '/offline-forms',
      icon: CloudOffIcon,
      label: 'Offline form',
      permissions: ['Forms.View'],
    },
    {
      linkTo: getSiteModuleRoute(siteId, module, 'tasks?view=list'),
      icon: AssignmentTurnedInIcon,
      label: 'Tasks',
      permissions: ['Tasks.View'],
    },
    {
      linkTo: getSiteModuleRoute(siteId, module, 'teams'),
      icon: GroupIcon,
      label: 'Teams',
      permissions: ['HQ.Admin'], // TODO: Permit 'Users.View' once something is demoable
    },
    {
      linkTo: '/library',
      icon: LibraryBooks,
      label: 'Library',
      permissions: ['Library.Download'],
    },
    {
      linkTo: getSiteModuleRoute(siteId, module, 'reports'),
      icon: ShowChartIcon,
      label: 'Reports',
      permissions: ['Reports.PageView'],
    },
    {
      linkTo: '/admin/sites',
      icon: LockIcon,
      label: 'Admin',
      permissions: ['Sites.View'],
    },
    {
      linkTo: '/hq',
      icon: LockIcon,
      label: 'HQ',
      permissions: ['HQ.Admin'],
    },
  ]);

  const expandState = navItems.reduce((o, key, index) => Object.assign(o, { [index]: false }), {});
  const [expanded, setExpanded] = React.useState<{ [x: number]: boolean }>(expandState);

  function NavIcon(item: INavItem) {
    const SpecificIcon = item.icon;
    return <SpecificIcon />;
  }

  const handleExpandClick = (index: number) => () => {
    const expand = !expanded[index];
    setExpanded({ [index]: expand });
  };

  function ListItemCollapseButton(listItemCollapseButtonProps: ListItemCollapseButtonProps) {
    const { icon, primary, index, className } = listItemCollapseButtonProps;
    return (
      <li>
        <ListItem button onClick={handleExpandClick(index)}>
          {icon ? <ListItemIcon className={className}>{icon}</ListItemIcon> : null}
          <ListItemText className={className} primary={primary} />
          <ListItemIcon
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded[index],
            })}
            aria-expanded={expanded[index]}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </ListItemIcon>
        </ListItem>
      </li>
    );
  }

  function OfflineFormsListItemLink(listItemLinkProps: ListItemLinkProps) {
    const offlineFormsCount = useGetOfflineFormsCount(userProfile);
    const { icon, to, className } = listItemLinkProps;
    const renderLink = React.useMemo(
      () =>
        React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
          <Link to={to} ref={ref} {...itemProps} />
        )),
      [to],
    );
    const primary = formatItemCount(offlineFormsCount, listItemLinkProps.primary);
    return (
      <>
        <li hidden={!offlineFormsCount}>
          <ListItem button component={renderLink}>
            {icon ? <ListItemIcon className={className}>{icon}</ListItemIcon> : null}
            <ListItemText className={className} primary={primary} />
          </ListItem>
        </li>
      </>
    );
  }

  const closeDrawer = () => {
    if (drawerOpen) {
      toggleDrawer();
    }
  };

  function ListItemLink(listItemLinkProps: ListItemLinkProps) {
    const { icon, primary, to, className } = listItemLinkProps;
    const renderLink = React.useMemo(
      () =>
        React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
          <Link to={to} ref={ref} {...itemProps} />
        )),
      [to],
    );
    return (
      <>
        <li>
          <ListItem button component={renderLink} onClick={closeDrawer}>
            {icon ? <ListItemIcon className={className}>{icon}</ListItemIcon> : null}
            <ListItemText className={className} primary={primary} />
          </ListItem>
        </li>
      </>
    );
  }

  function ListItemModuleLink(listItemLinkProps: ListItemLinkProps) {
    const { icon, primary, to, className } = listItemLinkProps;
    const renderLink = React.useMemo(
      () =>
        React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
          <Link to={getSiteModuleRoute(siteId, module, to)} ref={ref} {...itemProps} />
        )),
      [to],
    );
    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon className={className}>{icon}</ListItemIcon> : null}
          <ListItemText className={className} primary={primary} />
        </ListItem>
      </li>
    );
  }

  const siteControl = SiteControl();
  const moduleControl = ModuleControl();

  const drawer = (
    <>
      <SiteTitle />
      <div className={classes.scrollable}>
        <List component="nav">
          {navItems.map((item, index) => (
            <div key={item.label}>
              {userProfile &&
                (!item.permissions || userProfile.hasAnyPermission(item.permissions)) &&
                (!item.childLinks ? (
                  <>
                    {/* TODO: Review this */}
                    {item.label === 'Offline form' ? (
                      <OfflineFormsListItemLink
                        to={item.linkTo}
                        primary={item.label}
                        icon={NavIcon(item)}
                      />
                    ) : (
                      <ListItemLink to={item.linkTo} primary={item.label} icon={NavIcon(item)} />
                    )}
                  </>
                ) : (
                  <div key={item.label}>
                    <ListItemCollapseButton
                      primary={item.label}
                      icon={NavIcon(item)}
                      index={index}
                    />
                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                      <List>
                        {item.childLinks.map((x: ListItemLinkProps) => (
                          <ListItemModuleLink key={x.primary} {...x} />
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
            </div>
          ))}
        </List>
      </div>
      {siteControl && (
        <div className={clsx('nav--footer', classes.navFooter)}>
          {siteControl}
          <Divider />
        </div>
      )}
      {moduleControl && (
        <div className={clsx('nav--footer', classes.navFooter)}>
          {moduleControl}
          <Divider />
        </div>
      )}
      {/* Include an empty footer if nothing else - prevents the scrollbar from appearing */}
      {!siteControl && !moduleControl && <div className={clsx('nav--footer', classes.navFooter)} />}
    </>
  );
  return (
    <nav className={classes.root}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={drawerOpen}
          onClose={toggleDrawer}
          className="nav"
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
