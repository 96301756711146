import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  }),
);

interface IProps {
  message?: string;
}

export function EmptyMessage(props: IProps) {
  const classes = useStyles();
  const { message = 'No items' } = props;
  return <p className={classes.root}>{message}</p>;
}

