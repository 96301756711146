import { useGetMyTenant } from 'apollo-hooks';
import { QuickLinkProps } from 'components/Dashboard/QuickLinks';
import { WidgetProps } from 'components/Dashboard/Widgets';
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from './components/ActivityIndicator';
import { ErrorDisplay } from './components/ErrorDisplay';
import { IAssignee } from './formly/components/Assignees';
import { UserProfileContext } from './UserProfileContext';

type UiData = {
  showSiteControl?: boolean;
  quickLinks?: Array<QuickLinkProps>;
  automatedTaskDefaults?: {
    assigneesBySiteId?: {
      [siteId: number]: Array<IAssignee>;
    };
    taskDueInDays?: number;
  };
  taskCategories: Array<{
    name: string;
    value: string;
    color?: string;
  }>;
  dashboards?: Array<{
    permissions: Array<string>;
    widgets: Array<WidgetProps>;
  }>;
};

interface IContext {
  uiData: UiData | null;
}

const UiDataProviderContext = createContext<IContext | undefined>(undefined);

export function UiDataProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const userProfile = useContext(UserProfileContext);

  const { loading, data, error } = useGetMyTenant();
  const [uiData, setUiData] = useState<UiData | null>();

  useEffect(() => {
    if (data?.myTenant) {
      const { myTenant } = data;

      if (
        window.location.hostname !== 'localhost' &&
        window.location.hostname !== myTenant.domain
      ) {
        window.location.href = '/logout';
      }

      if (myTenant.uiData) {
        setUiData(JSON.parse(myTenant.uiData));
      } else {
        setUiData(null);
      }
    }
  }, [data]);

  if (!userProfile) {
    return <ErrorDisplay error="UiDataProvider must be used inside an AuthenticatedRoute" />;
  }

  if (loading || uiData === undefined) {
    return <ActivityIndicator />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <UiDataProviderContext.Provider value={{ uiData }}>{children}</UiDataProviderContext.Provider>
  );
}

export function useUiDataProvider(): IContext {
  const context = useContext(UiDataProviderContext);

  if (!context) {
    throw new Error('useUiDataProvider must be used inside a UiDataProvider.');
  }

  return context;
}
