import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import { HelpText } from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage, hasFieldError } from './shared/Validation';

export function PickList(props: IComponentProps) {
  const {
    siteId,
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
    customFunctions,
  } = props;

  const customTrigger = () => {
    const values = getValues();
    if (
      field.templateOptions?.required &&
      field.key &&
      (values[field.key] === undefined || values[field.key] === '')
    ) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    if (!readonly) {
      register({ name: field.key });
      setValue(field.key, modelValue);
      customTrigger();
    }
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  if (!field.templateOptions) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: Select without templateOptions: ${field.key}`);
    return null;
  }

  const options =
    field.templateOptions.optionNamesBySite?.[siteId.toString()]?.map((value) => ({
      name: value,
      value,
    })) ??
    field.templateOptions.options?.map((option) => ({
      name: option.name,
      value: option.value ?? option.name,
    })) ??
    [];

  if (options.length === 0) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: Select without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (field.key) {
      onValueUpdate(field.key, event.target.value);
      setValue(field.key!, event.target.value !== 'undefined' ? event.target.value : '');
      customTrigger();
      // TODO: Review - This was NOT how it was done originally
      // but there is a package on UAT (and perhaps prod) that
      // is stopping the original, better way from working
      if (field.data?.editsInstanceName === true && customFunctions && customFunctions.length) {
        if (typeof customFunctions[0] === 'function') {
          customFunctions[0](
            'editNameViaForm',
            (event.target.value !== 'undefined' ? event.target.value : '') as string,
          );
        }
      }
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl
          variant="outlined"
          required={!readonly && field.templateOptions?.required}
          disabled={readonly}
          error={hasFieldError(field.key!, errors, formState)}
        >
          <InputLabel id={field.key} required={!readonly && field.templateOptions?.required}>
            {field.templateOptions.label}
          </InputLabel>
          <Select
            label={field.templateOptions.label}
            labelId={field.key}
            id={field.key}
            onChange={handleChange}
            value={typeof modelValue !== 'undefined' ? modelValue : ''}
            fullWidth
          >
            {!field.templateOptions.required && <MenuItem value="">Select</MenuItem>}
            {options.map((option, i) => {
              const optionKey = `option_${i}`;
              return (
                <MenuItem key={optionKey} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
          {hasFieldError(field.key!, errors, formState) && (
            <FieldValidationMessage message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}
