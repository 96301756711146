import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getFilterLabel } from 'components/Tasks/utils';
import { format } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FilterOperation, FormFilterBy, TaskFilterBy } from 'tillr-graphql';
import { useUiDataProvider } from 'UiDataProvider';
import { UserProfileContext } from 'UserProfileContext';
import { FRIENDLY_DATE_FORMAT, getHumanFriendlyLabel, isArrayNotEmpty } from 'utils';
import { AssigneePickList } from './AssigneePickList';
import { TaskCategoryPickList } from './TaskCategoryPickList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
      minWidth: '200px',
    },
    filter: {
      paddingBottom: theme.spacing(2),
    },
    select: {
      width: '100%',
    },
    popover: {
      minWidth: '300px',
    },
  }),
);

export enum FilterType {
  Assignee,
  Boolean,
  Date,
  String,
  TaskCategory,
}

const filterTypeOperationMappings = new Map([
  [FilterType.Assignee, [FilterOperation.Equals]],
  [FilterType.Boolean, [FilterOperation.Equals]],
  [FilterType.Date, [FilterOperation.GreaterThan, FilterOperation.LessThan]],
  [
    FilterType.String,
    [FilterOperation.Contains, FilterOperation.StartsWith, FilterOperation.Equals],
  ],
  [FilterType.TaskCategory, [FilterOperation.Equals]],
]);

export interface IFilter<TFilterBy> {
  filterBy: TFilterBy;
  operation: FilterOperation;
  argument: string;
}

interface IProps<TFilterBy> {
  filterByValues: TFilterBy[];
  filterTypeMappings: Map<TFilterBy, FilterType>;
  onChange: (filter: IFilter<TFilterBy> | null, label?: string) => void;
}

export function FilterControl<TFilterBy extends string>(props: IProps<TFilterBy>) {
  const classes = useStyles();
  const { filterByValues, filterTypeMappings, onChange } = props;

  const userProfile = useContext(UserProfileContext)!;
  const { uiData } = useUiDataProvider();

  const isInitialRender = useRef(true);

  const [filterBy, setFilterBy] = useState<TFilterBy>(filterByValues[0]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [operation, setOperation] = useState(FilterOperation.Contains);
  const [argument, setArgument] = useState('');

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleChangeFilterBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextFilterBy = event.target.value as TFilterBy;
    setFilterBy(nextFilterBy);

    const nextFilterType = filterTypeMappings.get(nextFilterBy)!;
    const nextOperation = filterTypeOperationMappings.get(nextFilterType)![0];
    setOperation(nextOperation);

    // Reset argument whenever filter is changed
    setArgument('');

    onChange(null);
  };

  const handleChangeOperation = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextOperation = event.target.value as FilterOperation;
    setOperation(nextOperation);

    if (argument) {
      onChange(
        { filterBy, operation: nextOperation, argument },
        getFilterLabel(filterBy, filterTypeMappings.get(filterBy)!, nextOperation, argument),
      );
    }
  };

  const filterType = filterTypeMappings.get(filterBy);

  const checkPermissions = (_filterBy: TFilterBy): boolean => {
    switch (_filterBy) {
      case FormFilterBy.Field:
      case TaskFilterBy.Closed: {
        return false;
      }
      default: {
        switch (filterTypeMappings.get(_filterBy)) {
          case FilterType.Assignee: {
            return userProfile.hasAnyPermission(['Users.View']);
          }
          case FilterType.TaskCategory: {
            return isArrayNotEmpty(uiData?.taskCategories);
          }
          default:
            return true;
        }
      }
    }
  };

  const handleChangeArgument = (nextArgument: string, label?: string) => {
    setArgument(nextArgument);
    if (nextArgument) {
      onChange(
        { filterBy, operation, argument: nextArgument },
        getFilterLabel(
          filterBy,
          filterTypeMappings.get(filterBy)!,
          operation,
          label ?? nextArgument,
        ),
      );
    } else {
      onChange(null);
    }
  };

  const handleChangeBooleanArgument = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextArgument = event.target.value as string;
    setArgument(nextArgument);
    onChange(
      { filterBy, operation, argument: nextArgument },
      getFilterLabel(filterBy, filterTypeMappings.get(filterBy)!, operation, argument),
    );
  };

  const handleChangeDateArgument = (date: Date | null) => {
    if (date == null) {
      setArgument('');
    } else {
      const nextArgument = format(date, 'dd MMM yyyy');
      setArgument(nextArgument);
      onChange(
        { filterBy, operation, argument: nextArgument },
        getFilterLabel(filterBy, filterTypeMappings.get(filterBy)!, operation, nextArgument),
      );
    }
  };

  const handleChangeStringArgument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextArgument = event.target.value;
    setArgument(nextArgument);
    // Do not trigger onUpdate yet, wait until user has stopped typing (see useEffect timer below)
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return () => null;
    }
    if (filterType === FilterType.String) {
      // Wait until user has stopped typing (500ms delay)
      const timer = setTimeout(() => {
        if (argument) {
          onChange(
            { filterBy, operation, argument },
            getFilterLabel(filterBy, filterTypeMappings.get(filterBy)!, operation, argument),
          );
        } else {
          onChange(null);
        }
      }, 500);
      return () => clearTimeout(timer);
    }
    return () => null;
    // eslint-disable-next-line
  }, [argument]);

  const renderFilterInput = () => {
    switch (filterType) {
      case FilterType.Assignee: {
        return <AssigneePickList onChange={handleChangeArgument} value={argument} />;
      }
      case FilterType.Boolean: {
        return (
          <>
            <InputLabel id="filter-controls-boolean-label">true or false</InputLabel>
            <Select
              labelId="filter-controls-boolean-label"
              value={argument}
              onChange={handleChangeBooleanArgument}
            >
              {['true', 'false'].map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      }
      case FilterType.Date: {
        return (
          // REVIEW: if this is desired for all DatePickers, declare it once at the App level
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Select date"
              format={FRIENDLY_DATE_FORMAT}
              value={argument ? Date.parse(argument) : null}
              onChange={handleChangeDateArgument}
              animateYearScrolling
            />
          </MuiPickersUtilsProvider>
        );
      }
      case FilterType.String: {
        return (
          <TextField label="Filter on" onChange={handleChangeStringArgument} value={argument} />
        );
      }
      case FilterType.TaskCategory: {
        return <TaskCategoryPickList onChange={handleChangeArgument} value={argument} />;
      }
      default: {
        // TODO: const exhaustiveCheck: never = filterType;
        throw new Error(`Unknown filterType: ${filterType}.`);
      }
    }
  };

  const filterTypeOperations = filterTypeOperationMappings.get(filterTypeMappings.get(filterBy)!)!;

  return (
    <>
      <Tooltip title="Filter results">
        <IconButton
          color="inherit"
          aria-label="filter results"
          aria-haspopup="true"
          aria-controls="filter-results"
          onClick={handleFilterClick}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popover}
        id="filter-results"
        anchorEl={filterAnchorEl}
        keepMounted
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.wrapper}>
          <Grid item className={classes.filter}>
            <FormControl className={classes.select}>
              <InputLabel id="filter-controls-by-label">Filter by value</InputLabel>
              <Select
                labelId="filter-controls-by-label"
                id="filter-controls-by"
                value={filterBy}
                onChange={handleChangeFilterBy}
              >
                {filterByValues.filter(checkPermissions).map((x) => (
                  <MenuItem key={x} value={x}>
                    {getHumanFriendlyLabel(x)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {filterTypeOperations.length > 1 && (
            <Grid item className={classes.filter}>
              <FormControl className={classes.select}>
                <InputLabel id="filter-controls-type-label">Filter type</InputLabel>
                <Select
                  labelId="filter-controls-type-label"
                  id="filter-controls-type"
                  value={operation}
                  onChange={handleChangeOperation}
                >
                  {filterTypeOperations.map((x) => (
                    <MenuItem key={x} value={x}>
                      {getHumanFriendlyLabel(x)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item className={classes.filter}>
            <FormControl className={classes.select}>{renderFilterInput()}</FormControl>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
