import { Box, Paper, Typography } from '@material-ui/core';
import { IModel } from 'formly/IModel';
import React from 'react';

interface IProps {
  model: IModel;
}

export function ModelView(props: IProps) {
  const { model } = props;

  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Typography
            component="pre"
            style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
          >
            {JSON.stringify(model, null, 2)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
