import { Box, InputLabel, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { UserProfileContext } from 'UserProfileContext';
import { getRuleMessage } from './shared/Validation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sigCanvas: {
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[200],
    },
    sigImage: {
      marginBottom: theme.spacing(1),
      width: '100%',
      border: `solid 1px ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

function Signature(props: IComponentProps) {
  const classes = useStyles();
  const {
    field,
    modelValue,
    onValueUpdate,
    readonly,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = props;
  const userProfile = useContext(UserProfileContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [name, setName] = useState(
    (field.data?.signature?.setNameAsCurrentUser ? userProfile?.name : null) ?? '',
  );
  const [open, setOpen] = React.useState(false);
  const [signed, setSigned] = React.useState(modelValue && modelValue.length);
  const [signature, setSignature] = useState('');
  const [canvasContent, setCanvasContent] = useState('');
  const [orientationChange, setOrientationChange] = useState(false);
  const sigPad = useRef({});
  const widthRatio = 2.5;

  if ('onorientationchange' in window) {
    window.addEventListener(
      'orientationchange',
      () => {
        setTimeout(() => {
          setOrientationChange(!orientationChange);
        }, 250);
      },
      false,
    );
  }

  const measuredRef = useCallback(
    (node) => {
      const resizeCanvas = () => {
        // @ts-ignore
        const canvas = node.getCanvas();
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientWidth / widthRatio;
        node.clear();
      };
      if (node !== null) {
        sigPad.current = node;
        resizeCanvas();
      }
      return node;
    },
    // eslint-disable-next-line
    [widthRatio, orientationChange],
  );

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    if (!readonly) {
      register({ name: field.key });
      setValue(field.key, modelValue);
      customTrigger();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modelValue && modelValue.length) {
      const modelSplits = modelValue.split('%%%%');
      if (!modelSplits.length || modelSplits.length < 2) {
        return;
      }
      setName(modelSplits[0]);
      setSignature(modelSplits[1]);
      setValue(field.key, modelValue);
      customTrigger();
    }
    // eslint-disable-next-line
  }, [modelValue]);

  const handleSigneeChange = (event: any) => {
    setName(event.currentTarget.value);
  };

  const handleSignatureChange = () => {
    // @ts-ignore
    const dataURL = sigPad.current.toDataURL();
    setCanvasContent(dataURL);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    // @ts-ignore
    sigPad.current.clear();
    setCanvasContent('');
  };

  const isFormValid = name && canvasContent;

  const handleSave = () => {
    if (field.key && isFormValid) {
      setSignature(canvasContent);
      const value = `${name}%%%%${canvasContent}`;
      onValueUpdate(field.key, value);
      setSigned(true);
      setOpen(false);
    }
  };

  return (
    <Grid item xs={12} className="tillr-form-grid-element">
      <Box mb={1}>
        <InputLabel required={!readonly && field.templateOptions?.required}>
          {field.templateOptions?.label}
        </InputLabel>
      </Box>
      {signed ? (
        <>
          {signature && signature.length && (
            <>
              <Grid item xs={12} md={6} className="tillr-form-grid-element">
                <img className={classes.sigImage} alt={name} src={signature} />
              </Grid>
              <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
            </>
          )}
          <Grid item xs={12} md={6} className="tillr-form-grid-element">
            <TextField
              label="Your name"
              id="signee"
              value={name || ''}
              variant="outlined"
              onChange={handleSigneeChange}
              required={field.templateOptions?.required}
              disabled
              fullWidth
            />
          </Grid>
          {!readonly && (
            <>
              <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
              <Grid item xs={12} md={6} className="tillr-form-grid-element">
                <Box mt={1}>
                  <Tooltip title="Click to sign your name">
                    <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                      <BorderColorIcon />
                      Redo signature
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          {readonly ? (
            <Typography variant="body2" style={{ fontStyle: 'italic' }}>
              No signature
            </Typography>
          ) : (
            <Grid item xs={12} md={6} className="tillr-form-grid-element">
              <Tooltip title="Click to sign your name">
                <Button variant="contained" color="secondary" onClick={handleClickOpen}>
                  <BorderColorIcon />
                  Sign here
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={!fullScreen}
      >
        <DialogTitle id="form-dialog-title">Your signature</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sign in the box below using your finger, mouse or trackpad.
          </DialogContentText>
          <Grid item xs={12} sm={12} className="tillr-form-grid-element">
            <SignatureCanvas
              canvasProps={{
                className: classes.sigCanvas,
              }}
              ref={measuredRef}
              onEnd={handleSignatureChange}
            />
            <TextField
              label="Your name"
              id="signee"
              value={name || ''}
              variant="outlined"
              onChange={handleSigneeChange}
              required
              fullWidth
            />
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClear} disabled={!canvasContent} color="primary">
              Clear
            </Button>
            <Button onClick={handleSave} disabled={!isFormValid} color="primary">
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export { Signature };
