import { InputAdornment, OutlinedInput } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

interface IProps {
  item: string;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

export function NumberInput(props: IProps) {
  const { item, value, onChange, disabled } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const nextDays = Number(value.replace(/\D/g, ''));

    onChange(nextDays);
  };

  const itemLabel = value === 1 ? item : `${item}s`;

  return (
    <OutlinedInput
      style={{ width: '6.5rem' }}
      margin="dense"
      value={value}
      onChange={handleChange}
      endAdornment={<InputAdornment position="end">{itemLabel}</InputAdornment>}
      inputProps={{
        'aria-label': itemLabel,
      }}
      disabled={disabled}
    />
  );
}

