import { styled } from '@material-ui/core';
import { ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  '& > .MuiToggleButton-label': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-selected > .MuiToggleButton-label': {
    color: 'white',
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IProps<TValue> {
  values: Array<TValue>;
  value: TValue;
  onChange: (nextValue: TValue) => void;
  'aria-label'?: string;
}

export function ToggleButtons<TValue extends string>(props: IProps<TValue>) {
  const { values, value, onChange, 'aria-label': ariaLabel } = props;

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, nextValue) => nextValue && onChange(nextValue)}
      size="small"
      aria-label={ariaLabel}
    >
      {values.map((v) => (
        <ToggleButton key={v} value={v} aria-label={v} disableRipple>
          {v}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

