import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useGetFormTemplateAndSites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { getSiteModuleRoute } from 'components/route-utils';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useContext } from 'react';
import { HelpText } from './shared/HelpText';

export function SelectSite(props: IComponentProps) {
  const { field, siteId, formTemplateId, mode } = props;

  const { module } = useContext(ModuleContext)!;

  const queryState = useGetFormTemplateAndSites({ siteId, module, formTemplateId });

  const options = queryState.data?.formTemplate?.sites?.map((site) => ({
    name: site.fullName,
    value: site.id,
  }));

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nextSiteId = Number(event.target.value);

    window.location.href = getSiteModuleRoute(
      nextSiteId,
      module,
      `/form-templates/${formTemplateId}/forms/add`,
    );
  };

  const readonly = mode !== 'create';

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        {queryState.error ? (
          <ErrorDisplay errorMessage="Error loading sites. Please refresh your browser, or contact support@tillr.io if the problem persists." />
        ) : (
          <FormControl variant="outlined" disabled={readonly}>
            <InputLabel id={field.key}>
              <Box display="flex" alignContent="center">
                {field.templateOptions?.label}
                {queryState.loading && (
                  <Box ml={1}>
                    <ActivityIndicator size="small" />
                  </Box>
                )}
              </Box>
            </InputLabel>
            <Select
              label={field.templateOptions?.label}
              labelId={field.key}
              id={field.key}
              onChange={handleChange}
              value={siteId}
              fullWidth
            >
              {options?.map((option, i) => {
                const optionKey = `option_${i}`;
                return (
                  <MenuItem key={optionKey} value={option.value}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
            {field.data?.help && <HelpText value={field.data?.help} />}
          </FormControl>
        )}
      </Grid>
    </>
  );
}
