import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      borderRadius: '3px',
      color: 'white',
      display: 'inline-block',
      marginRight: '4px',
      textTransform: 'uppercase',
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    danger: {
      backgroundColor: theme.palette.error.main,
    },
  }),
);

interface IProps {
  children: React.ReactNode;
  color: 'primary' | 'secondary' | 'danger';
}

export function TitlePrefix(props: IProps) {
  const classes = useStyles();
  const { children, color } = props;
  return <strong className={clsx(classes.root, classes[color])}>{children}</strong>;
}

