/* eslint react/prop-types: 0 */
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { MandatoryFieldMessage } from 'components/Shared/MandatoryFieldMessage';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUiDataProvider } from 'UiDataProvider';
import { includeBySiteId } from 'utils';
import { CollapsableGroup } from './components/CollapsableGroup';
import { FormMode } from './FormMode';
import { getFieldTypeComponent } from './getFieldTypeComponent';
import { IComponentProps } from './IComponentProps';
import { IFormlyConfig, IFormlyField } from './IFormlyConfig';
import { IModel } from './IModel';
import { ModelView } from './ModelView';
import { SubmitButton } from './SubmitButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      marginBottom: theme.spacing(2),
    },
  }),
);

interface IProps {
  config: IFormlyConfig;
  formId?: string;
  formTemplateId?: string;
  mode?: FormMode;
  model?: IModel;
  onCancel?: () => void;
  onSubmit?: (model: any, submit?: boolean) => void;
  submitting?: boolean;
  modal?: boolean;
  children?: React.ReactNode;
  customFunctions?: ((...args: any[]) => void)[];
  customButtons?: boolean;
  fixedButtons?: boolean;
  hideMandatoryMessage?: boolean;
  debug?: boolean;
}

interface IAutomatedTaskList {
  [key: string]: IModel[];
}

export function FormlyForm(props: IProps) {
  const { handleSubmit, formState, clearErrors, unregister, ...useFormProps } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    config: { fields, settings },
    formId,
    formTemplateId,
    mode = 'create',
    model: initialModel = {},
    onCancel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit,
    submitting,
    modal,
    children,
    customFunctions = [],
    customButtons = false,
    fixedButtons = false,
    hideMandatoryMessage = false,
    debug = false,
  } = props;
  const classes = useStyles();

  const { uiData } = useUiDataProvider();
  const siteContext = useContext(SiteContext);
  const siteId = siteContext?.siteId ?? 0;

  const [newAutomatedTasksList, setNewAutomatedTasksList] = useState<IAutomatedTaskList>({});
  const [automatedTasksOpen, setAutomatedTasksOpen] = useState<boolean>(false);
  const [filteredModel, setFilteredModel] = useState<IModel>({});
  let toRemove: string[] = [];

  function initialiseModel(model: any, formFields: IFormlyField[]) {
    if (mode !== 'create') return;
    formFields.forEach((x) => {
      if (x.key && x.defaultValue != null && includeBySiteId(x.data, siteId)) {
        if (x?.type?.toLowerCase().match(/timepicker/)) {
          // An unset timepicker is given an empty string default value by
          // Form Builder. But an empty string means it exists in the form's model
          // when the form initialises
          if (x.defaultValue.length) {
            // eslint-disable-next-line no-param-reassign
            model[x.key] = x.defaultValue;
          }
        } else if (x?.type?.toLowerCase().match(/taskcategory/)) {
          // Task categories are unknown in the template so the defaultValue is in the index
          const index = Number(x.defaultValue);
          if (x.defaultValue && Number.isInteger(index)) {
            model[x.key] = uiData?.taskCategories?.[index]?.value;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          model[x.key] = x.defaultValue;
        }
      }
      if (x.fieldGroup) {
        initialiseModel(model, x.fieldGroup);
      }
    });
  }

  initialiseModel(initialModel, fields);

  const [model, setModel] = useState(initialModel);

  const getNewAutomatedTasksTotal = () => {
    let totalTasks = 0;
    Object.keys(newAutomatedTasksList).forEach((list) => {
      totalTasks += newAutomatedTasksList[list].length;
    });
    return totalTasks;
  };

  const trackAutomatedTasks = (key: string, tasks: IModel[]) => {
    const taskList = newAutomatedTasksList;
    if (tasks.length) {
      taskList[key] = tasks.filter((task: IModel) => !task.id);
    } else {
      taskList[key] = tasks;
    }
    setNewAutomatedTasksList(taskList);
  };

  async function handleOnSubmit(submit: boolean): Promise<void> {
    // Filter model and automated tasks
    // to only include elements that are
    // still part of the form (have not
    // since been hidden by any fired
    // dynamic rules)
    const newModel = (await new Promise((resolve) => {
      const filtered = { ...model };
      // eslint-disable-next-line array-callback-return
      toRemove.map((key) => {
        // Remove from model
        if (typeof filtered[key] !== 'undefined') {
          delete filtered[key];
        }
        // Remove from automated tasks
        setNewAutomatedTasksList((previous) => {
          if (typeof previous[key] !== 'undefined') {
            // eslint-disable-next-line no-param-reassign
            delete previous[key];
          }
          return previous;
        });
      });
      resolve(filtered);
    })) as IModel;

    // If there are new automated tasks,
    // warn the user
    if (getNewAutomatedTasksTotal()) {
      setFilteredModel(newModel);
      setAutomatedTasksOpen(true);
      return;
    }

    // No new automated tasks, so crack on
    if (onSubmit) {
      onSubmit(newModel, submit);
    }
  }

  const handleCloseAutomatedTasks = () => {
    setAutomatedTasksOpen(false);
  };

  const handleContinueAutomatedTasks = (createTasks: boolean = false) => {
    handleCloseAutomatedTasks();
    if (onSubmit) {
      const data = {
        createTasks,
        model: filteredModel,
      };
      onSubmit(data);
    }
  };

  // If an element is hidden, via a rule, it musn't appear in the model
  const removeFromModel = (key: string | undefined) => {
    if (key && model[key] !== undefined) {
      // const { [key]: value, ...updated } = model;
      // setModel(updated);
      if (!toRemove.includes(key)) {
        toRemove = toRemove.concat([key]);
      }
    }
  };

  // If an element is hidden, via a rule, it musn't be validated
  const removeFromValidation = (key: string | undefined) => {
    if (!key) return;
    unregister(key);
  };

  const onValueUpdate = (fieldKey: string, value: any) => {
    setModel((prevState) => ({ ...prevState, [fieldKey]: value }));
  };

  const fireDynamicRule = (hideExpression: string, key: string | undefined) => {
    try {
      // eslint-disable-next-line no-new-func, @typescript-eslint/no-implied-eval
      const func = new Function('model', `return ${hideExpression}`);
      if (func(model)) {
        removeFromModel(key);
        removeFromValidation(key);
        return null;
      }
      // This is where we can fix the default value for hidden then
      // shown elements
    } catch {
      // If there was an error, the key doesn't exist in the model. BUT
      // this might be what we're testing for, so we can't *just* return null
      try {
        // Have to get the key like this as, if someone were to enter
        // a GUID in the field, it woudn't work. Have to match on "model['..."
        const field = hideExpression.match(/^model\['[A-z0-9-]+/);
        if (field) {
          const fieldKey = field[0].split("model['");
          // If the key isn't in the model, determine if we are looking for
          // a particular value to be present. If we are, it's obviously false
          if (!model[fieldKey[1]]) {
            return hideExpression.match(/===/);
          }
          return null;
        }
      } catch {
        // If we still have an error, something is wrong, so NOW
        // we can just return null
        return null;
      }
      return null;
    }
    return true;
  };

  function renderFields(fieldGroup: IFormlyField[], hideGroup?: boolean): (JSX.Element | null)[] {
    let groupIsHidden: boolean = hideGroup || false;

    return fieldGroup.map((field, i) => {
      const key = `fieldgroup_${i}`;
      if (field.fieldGroup?.length) {
        if (!includeBySiteId(field.data, siteId)) {
          return null;
        }

        // If this is a group and it has a hideExpression, store the outcome
        // so we can remove it's children from the model and validation
        if (field?.className?.match(/group/) && field.hideExpression) {
          groupIsHidden = !fireDynamicRule(field.hideExpression, field.data?.key);
        }

        const childFields = renderFields(field.fieldGroup, groupIsHidden);
        if (childFields.some((x) => x != null)) {
          if (settings?.collapseGroups === true && field?.data?.format === 'group') {
            return (
              <CollapsableGroup index={i} data={field.data} key={key}>
                {childFields}
              </CollapsableGroup>
            );
          }
          return <React.Fragment key={key}>{childFields}</React.Fragment>;
        }

        // All children complete, so reset
        groupIsHidden = false;

        return null;
      }

      if (
        groupIsHidden || // If parent group is hidde, remove it
        (field.hideExpression && !fireDynamicRule(field.hideExpression, field?.key))
      ) {
        removeFromModel(field?.key);
        removeFromValidation(field?.key);
        return null;
      }

      if (field.key && field.type && includeBySiteId(field.data, siteId)) {
        const fieldComponent = getFieldTypeComponent(field.type);
        const componentProps: IComponentProps = {
          formId,
          formTemplateId,
          siteId,
          field,
          customFunctions,
          modelValue: model[field.key],
          onValueUpdate,
          mode,
          readonly: mode === 'readonly',
          formState,
          trackAutomatedTasks,
        };
        if (field.type === 'automatedtasks') {
          componentProps.model = model;
          componentProps.settings = settings;
          componentProps.fields = fields;
          // componentProps.onValueUpdate = onTaskAdded;
        }
        return React.createElement(fieldComponent, {
          key: field.key,
          ...componentProps,
          formState,
          clearErrors,
          ...useFormProps,
        });
      }
      return null;
    });
  }

  function renderForm() {
    return (
      <>
        {mode !== 'readonly' && !hideMandatoryMessage && <MandatoryFieldMessage />}
        <form onSubmit={handleSubmit(() => handleOnSubmit(false))} noValidate autoComplete="off">
          <Grid container spacing={3}>
            {renderFields(fields)}
            {mode !== 'readonly' && !model && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || !formState.isDirty || !formState.isValid}
                >
                  {(settings ? settings[mode]?.save : null) || 'Submit'}
                </Button>
                {onCancel && settings && settings[mode]?.cancel && (
                  <Button variant="contained" onClick={onCancel} disabled={submitting}>
                    {settings[mode]!.cancel}
                  </Button>
                )}
                {submitting && <span>Submitting...</span>}
              </Grid>
            )}
          </Grid>
          {children}
          {!customButtons && mode !== 'readonly' && model && (
            <FormsButtons fixedButtons={fixedButtons}>
              {onCancel && (
                <CancelButton
                  label={settings?.manage?.cancel ? settings.manage.cancel : 'Cancel'}
                  loading={submitting}
                  onClick={onCancel}
                />
              )}
              <ProgressButton
                label={settings?.manage?.save ? settings.manage.save : 'Save'}
                loading={submitting}
                type="submit"
                isValid={settings?.canSubmit || formState.isValid}
              />
              {settings?.canSubmit && (
                <SubmitButton
                  label={settings?.manage?.submit ? settings.manage.submit : 'Submit'}
                  submitting={submitting}
                  isValid={formState.isValid}
                  onSubmit={handleSubmit(() => handleOnSubmit(true))}
                />
              )}
            </FormsButtons>
          )}
        </form>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={automatedTasksOpen}
          onClose={handleCloseAutomatedTasks}
          aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title">New Automated Tasks</DialogTitle>
          <DialogContent>
            <Typography variant="body1" display="block" className={classes.modalTitle}>
              This form contains
              <strong>{` ${getNewAutomatedTasksTotal()} `}</strong>
              new task(s).
            </Typography>
            <Typography variant="body1" display="block">
              {/* eslint-disable-next-line max-len */}
              You can choose to save this form and create and assign the task(s), or just save the
              form and create and assign the task(s) at a later date. Alternatively, you can close
              this message and continue editing this form.
            </Typography>
          </DialogContent>
          <DialogActions className="flex-d-col flex-a-end">
            {/* onCancel */}
            <Button onClick={handleCloseAutomatedTasks} color="primary">
              Close and continue editing
            </Button>
            <Button onClick={() => handleContinueAutomatedTasks(false)} color="primary">
              Save form without creating new task(s)
            </Button>
            <Button onClick={() => handleContinueAutomatedTasks(true)} color="primary">
              Save form and create new task(s)
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      {modal ? (
        renderForm()
      ) : (
        <Card>
          <CardContent>{renderForm()}</CardContent>
        </Card>
      )}
      {debug && <ModelView model={model} />}
    </>
  );
}
