import { Divider, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      padding: theme.spacing(0.25, 0.5, 0.25, 0.5),
      display: 'flex',
      alignItems: 'center',
    },
    searchInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    searchIcon: {
      padding: theme.spacing(1),
    },
    hidden: {
      visibility: 'hidden',
    },
    searchDivider: {
      height: 28,
      margin: theme.spacing(0.5),
    },
  }),
);

interface IProps {
  handleChange: (term: string) => void;
  handleClear: () => void;
  query: string;
}

export function SearchBox(props: IProps) {
  const { handleChange, handleClear, query } = props;
  const classes = useStyles();

  const [term, setTerm] = useState(query);

  return (
    <>
      <Paper component="form" className={classes.search}>
        <InputBase
          className={classes.searchInput}
          placeholder="Search Library"
          inputProps={{ 'aria-label': 'search library' }}
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              handleChange(term);
            }
          }}
        />
        <IconButton
          onClick={handleClear}
          className={query.length ? classes.searchIcon : classes.hidden}
          aria-label="Clear search"
        >
          <ClearIcon />
        </IconButton>
        <Divider className={classes.searchDivider} orientation="vertical" />
        <IconButton
          onClick={() => handleChange(term)}
          className={classes.searchIcon}
          aria-label="Search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  );
}
