import React from 'react';
import { useUiDataProvider } from 'UiDataProvider';

interface IProps {
  value: string;
}

export function TaskCategoryLabel(props: IProps) {
  const { value } = props;

  const { uiData } = useUiDataProvider();

  const taskCategory = uiData?.taskCategories.find((x) => x.value === value);

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <span style={{ color: taskCategory?.color }}>⬤</span>&nbsp;{taskCategory?.name ?? value}
    </span>
  );
}
