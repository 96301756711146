import { ParentType } from 'tillr-graphql';
import { IFormlyConfig } from './IFormlyConfig';

export function getStaticTemplate(type: string): IFormlyConfig {
  switch (type) {
    case 'edit-form-template': {
      return {
        fields: [
          {
            id: 'edit-formTemplate-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'edit-formTemplate-template',
            type: 'textarea',
            templateOptions: {
              label: 'Template JSON',
              required: true,
            },
            key: 'template',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'edit-formTemplate-newFormTemplateId',
            type: 'input',
            templateOptions: {
              label: 'New FormTemplateId (if not replacing)',
            },
            key: 'newFormTemplateId',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    }
    case 'create-report': {
      return {
        fields: [
          {
            id: 'reportform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-sqlQuery',
            type: 'textarea',
            templateOptions: {
              label: 'SQL Query',
              required: true,
            },
            key: 'sqlQuery',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-uiData',
            type: 'textarea',
            templateOptions: {
              label: 'UiData',
              required: false,
            },
            key: 'uiData',
            className: 'col-xs-6',
          },
          {
            id: 'reportform-uiDataCopy',
            type: 'copy',
            defaultValue: `Format: <pre>{
  "pivot"?: {
    "keyColumn": string,
    "keyColumnFormat"?: string,
    "valueColumn": string
  },
  "views": [{
    "title": string,
    "type": "Bar" | "Pie" | "Sunburst" | "Table",
    "groupBy"?: string,
    "thenGroupBy"?: string
  }]
}</pre>`,
            key: 'uiDataCopy',
            className: 'col-xs-6',
          },
          {
            id: 'reportform-reportingData',
            type: 'tlrSelect',
            templateOptions: {
              label: 'Reporting database',
              required: true,
              options: [
                { name: 'System', value: 'SYSTEM' },
                { name: 'Tenant', value: 'TENANT' },
              ],
            },
            key: 'reportingDatabase',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'reportform-isPublished',
            type: 'checkbox',
            templateOptions: {
              label: 'Is Published?',
              required: true,
            },
            key: 'isPublished',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    }
    case 'create-automated-task':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Task name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
            data: {
              help: 'Edit this value to give the task a more identifiable name',
            },
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: false,
              label: 'Additional comments',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'duedate',
            data: {},
            templateOptions: {
              required: true,
              label: 'Due date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
        ],
      };
    case 'role':
      return {
        fields: [
          {
            id: 'role-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'role-id',
            type: 'hidden',
            templateOptions: {
              label: 'ID',
              required: true,
            },
            key: 'id',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    case 'create-task-from-calendar':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'input',
            data: {},
            templateOptions: {
              label: 'Due date',
              disabled: true,
            },
            className: 'col-xs-6 clear',
            key: 'dueDateFriendly',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'quick-task':
      return {
        fields: [
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignedToUsers',
            className: 'col-xs-12 col-sm-12',
            hideExpression: 'true',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {},
            templateOptions: {
              required: true,
              label: 'Due date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
          },
          {
            id: 'taskform-attachments',
            type: 'attachments',
            templateOptions: {
              required: false,
              label: 'Attachments',
            },
            data: {
              parentTypeForAttachments: ParentType.Task,
            },
            className: 'col-xs-12 col-sm-12',
            key: 'attachments',
          },
        ],
      };
    case 'task-quick-view':
      return {
        fields: [
          {
            id: 'taskform-status',
            type: 'tlrSelect',
            templateOptions: {
              required: true,
              options: [
                {
                  name: 'Open',
                  value: true,
                },
                {
                  name: 'Closed',
                  value: false,
                },
              ],
              label: 'Status',
            },
            className: 'col-xs-6 clear',
            key: 'open',
            defaultValue: 'Open',
            hideExpression: 'true',
          },
          {
            id: 'taskform-completed',
            type: 'datepicker',
            data: {},
            templateOptions: {
              label: 'Completed date',
              required: true,
            },
            className: 'col-xs-6 pull-right',
            key: 'completedDate',
            hideExpression: 'true',
          },
          {
            id: 'taskform-name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true,
            },
            key: 'name',
            className: 'col-xs-12 col-sm-12',
            hideExpression: 'true',
          },
          {
            id: 'taskform-description',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Description',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'description',
          },
          {
            id: 'taskform-assignees',
            type: 'assignees',
            templateOptions: {
              label: 'Assignee(s)',
              required: true,
            },
            key: 'assignees',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'taskform-duedate',
            type: 'datepicker',
            data: {},
            templateOptions: {
              required: true,
              label: 'Due date',
            },
            className: 'col-xs-6 clear',
            key: 'dueDate',
            hideExpression: 'true',
          },
        ],
      };
    case 'task-note':
      return {
        fields: [
          {
            id: 'note-module',
            type: 'hidden',
            key: 'module',
          },
          {
            id: 'note-task-id',
            type: 'hidden',
            key: 'taskId',
          },
          {
            id: 'note-note',
            type: 'textarea',
            templateOptions: {
              required: true,
              label: 'Add note',
            },
            className: 'col-xs-12 col-sm-12',
            key: 'note',
          },
        ],
      };
    case 'create-site':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Site name',
            },
          },
          {
            id: 'externalId',
            type: 'input',
            key: 'externalId',
            templateOptions: {
              required: false,
              label: 'External ID',
            },
          },
        ],
      };
    case 'edit-site-name':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Site name',
            },
          },
        ],
      };
    case 'add-folder':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Folder name',
            },
          },
        ],
      };
    case 'admin-view-user':
      return {
        fields: [
          {
            id: 'firstName',
            type: 'input',
            templateOptions: {
              label: 'First name',
            },
            key: 'firstName',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'lastName',
            type: 'input',
            templateOptions: {
              label: 'Last name',
            },
            key: 'lastName',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email address',
            },
            key: 'email',
            className: 'col-xs-12 col-sm-12',
          },
          {
            id: 'role',
            type: 'input',
            templateOptions: {
              label: 'Role',
            },
            key: 'role',
            className: 'col-xs-12 col-sm-12',
          },
        ],
      };
    default:
      return { fields: [] };
  }
}
