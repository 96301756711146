import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { useGetTeamsAndUsers } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { ChangeEvent, useContext } from 'react';
import { sortByName } from 'utils';

const LABEL = 'Select assignee';

interface IProps {
  value: string;
  onChange: (assigneeId: string, assigneeName: string) => void;
}

export function AssigneePickList(props: IProps) {
  const { value, onChange } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeamsAndUsers({ siteId, module });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const assigneeId = event.target.value as string;
    const assigneeName =
      data?.users?.find((x) => x.id === assigneeId)?.name ??
      data?.teams?.find((x) => x.id === assigneeId)?.name ??
      '';
    onChange(assigneeId, assigneeName);
  };

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && (
        <>
          <InputLabel id="assigneePickListLabel">{LABEL}</InputLabel>
          <Select
            label={LABEL}
            labelId="assigneePickListLabel"
            onChange={handleChange}
            value={value}
          >
            <MenuItem value="">– Show all –</MenuItem>
            {data.users?.length && [
              data.teams?.length && (
                <MenuItem key="Users" disabled>
                  Users
                </MenuItem>
              ),
              data.users
                .slice()
                .sort(sortByName)
                .map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                )),
            ]}
            {data.teams?.length && [
              <MenuItem key="Teams" disabled>
                Teams
              </MenuItem>,
              data.teams
                .slice()
                .sort(sortByName)
                .map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                )),
            ]}
          </Select>
        </>
      )}
    </>
  );
}
